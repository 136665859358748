import { Box , SxProps} from "@mui/system";

interface iGreyBox {
    className?: string;
    children: React.ReactNode;
    height?:number|string;
    overflow?:'horizontal';
}

export const alwaysFullSpacing: any = {
    md: 12,
    sm: 12,
    xs: 12,
    lg: 12,
    xl: 12
}


export const containerSpacing: any = {
    xl: 6,
    lg: 6,
    md: 6,
    sm: 12,
    xs: 12,
}

const GreyBox = ({ children, className = '', overflow, height}: iGreyBox) => {
    const borderSX:SxProps = {
        border: 1,
        color: "black",
        borderColor: "#dd3f3c",
        backgroundColor: "#e0e0e0",
        padding: 0.5,
        margin: 1,
        marginTop: 2,
        borderRadius: 0,
    }

    if(overflow === 'horizontal'){
       borderSX.overflow ="auto";
    }
    if(height){
        borderSX.height = height;
    }
    return <Box sx={borderSX} className={`boxxx ${className}`}>{children}</Box>
}


export default GreyBox;