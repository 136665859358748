import { Route, Routes } from "react-router-dom";
import SecureRoute from "../../util/secureRoute";
import Quote from "./quote";
import Quotes from "./quotes";


const QuotesRoutes = () => {

    return <Routes>
        <Route path="/" element={<SecureRoute title="Quotes"><Quotes /></SecureRoute>} />
        <Route path="/:id" element={<SecureRoute title="Quote"><Quote /></SecureRoute>} />
    </Routes>
}


export default QuotesRoutes;