
import CallMadeIcon from '@mui/icons-material/CallMade';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import PhoneIcon from '@mui/icons-material/Phone';
import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Approve from './approve';
import Decline from './decline';
import Download from './download';
interface iBurger {
    actions: any[];
    row: any;
    onClose: () => void;
}


const BurgerMenu = ({ actions, row, onClose }: iBurger) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handelOnClose = () => {
        handleClose()
        onClose();
    }
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return <>{actions.length > 0 && <><Button
        id="basic-button"
        className='burger-button '
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        size="small"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
    >
        {open ? <MenuOpenIcon /> : <MenuIcon />}
    </Button>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {actions.map((act, iAct) => {
                const Field = act.F;
                const Content = row[Field];
                switch (act.LF) {
                    case 'Line':
                        return <Divider key={iAct} />

                    case "APPROVE":
                        return <MenuItem key={iAct} onClick={() => {
                            // handleClose()
                        }}><ListItemIcon>
                                <CheckIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Approve className='burger-button' object={row} jobId={Content} variant="text" onClose={handelOnClose} />

                            </ListItemText></MenuItem>
                    case "DECLINE":
                        return <MenuItem key={iAct} onClick={() => {
                            // handleClose()
                        }}><ListItemIcon>
                                <ClearIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Decline className='burger-button' jobId={Content} variant="text" onClose={handelOnClose} />

                            </ListItemText></MenuItem>

                    case "FILE":
                        //console.log(act)
                        return <MenuItem key={iAct} onClick={() => {
                            handleClose()
                        }}><ListItemIcon>
                                <DownloadIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Download className='burger-button' variant='text' cacheId={Content} buttonText={act.FN} />
                                {/* <Button variant='text' onClick={(ev) => {
                                    ev.stopPropagation();
                                    const Go = async () => {
                                        //approveQuote

                                        console.log("GET", Content)

                                        axios({
                                            url: `/api/cache/${Content}`, //your url
                                            method: 'GET',
                                            responseType: 'blob', // important
                                        }).then((response: any) => {
                                            console.log("GET", response.data)
                                            if (response.data.size > 100) {
                                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                                const link = document.createElement('a');
                                                link.href = url;
                                                link.setAttribute('download', row.CacheFileName); //or any other extension
                                                document.body.appendChild(link);
                                                link.click();
                                                handleClose()
                                            } else {
                                                alert("Cant Find Cache");
                                                handleClose()
                                            }

                                        });
                                        // const response = await doRequest<any>(`cache/ ${Content}`, 'GET', {})
                                        //

                                    }
                                    Go();
                                }}>{act.FN}
                                </Button> */}
                            </ListItemText>

                        </MenuItem>
                    case "OPENQUOTE":
                        const linkQuote = `/quote/${Content}`
                        return <MenuItem key={iAct} onClick={() => {
                            navigate && navigate(linkQuote, { replace: false });
                            handleClose()
                        }}><ListItemIcon>
                                <CallMadeIcon />
                            </ListItemIcon>
                            <ListItemText><Button className='burger-button' variant='text'>{act.FN}</Button></ListItemText></MenuItem>
                    case "OPENJOB":
                        const linkJob = `/jobs/${Content}`
                        return <MenuItem key={iAct} onClick={() => {
                            navigate && navigate(linkJob, { replace: false });
                            handleClose()
                        }}><ListItemIcon>
                                <CallMadeIcon />
                            </ListItemIcon>
                            <ListItemText><Button className='burger-button' variant='text'>{act.FN}</Button></ListItemText></MenuItem>
                    case "OPENREPAIR":
                        const jobID = (row['JobID'] ? row['JobID'] : 'no field');
                        return <MenuItem key={iAct} onClick={() => {
                            navigate && navigate(`/jobs/${jobID}`, { replace: false });
                            handleClose()
                        }}><ListItemIcon>
                                <CallMadeIcon />
                            </ListItemIcon>
                            <ListItemText><Button className='burger-button' variant='text'>{act.FN}</Button></ListItemText></MenuItem>
                    case "OPENAPPLIANCE":

                        return <MenuItem key={iAct} onClick={() => {
                            navigate && navigate(`/assets/${Content}`, { replace: false });
                            handleClose()
                        }}><ListItemIcon>
                                <CallMadeIcon />
                            </ListItemIcon>
                            <ListItemText><Button className='burger-button' variant='text'>{act.FN}</Button></ListItemText></MenuItem>
                    case "OPENLOCATION":
                        const link = `/locations/${Content}`
                        return <MenuItem key={iAct} onClick={() => {
                            navigate && navigate(link, { replace: false });
                            handleClose()
                        }}><ListItemIcon>
                                <CallMadeIcon />
                            </ListItemIcon>
                            <ListItemText><Button className='burger-button' variant='text'>{act.FN}</Button></ListItemText></MenuItem>

                    case 'Phone':
                        //const link = 
                        return <MenuItem key={iAct} onClick={() => {
                            handleClose()
                        }}><ListItemIcon>
                                <PhoneIcon />
                            </ListItemIcon>
                            <Button variant='text' className='burger-button' href={`tel:${Content}`}><ListItemText>{act.FN}</ListItemText></Button></MenuItem>

                    case 'Email':
                        //const link = 
                        return <MenuItem key={iAct} onClick={() => {
                            handleClose()
                        }}><ListItemIcon>
                                <EmailIcon />
                            </ListItemIcon>
                            <Button variant='text' className='burger-button' href={`mailto:${Content}?subject=Email`}><ListItemText>{act.FN}</ListItemText></Button>


                        </MenuItem>

                    default:
                        return <MenuItem key={iAct} onClick={() => {
                            handleClose()
                        }}>{act.FN} TYPE FAILED {act.LF}</MenuItem>
                }




            })}

        </Menu>
    </>}</>
}

export default BurgerMenu