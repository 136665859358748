import { Button, CircularProgress, Grid, Paper, TableHead } from "@mui/material";
import GreyBox, { alwaysFullSpacing } from "../../util/greyBox";

import { useEffect, useMemo, useRef, useState } from "react";
import doRequest from "../../util/axios";
import { SxProps, useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useAuth } from "../../util/context";


import { Order, ScheduleHeader } from "./utils";
import { useNavigate } from "react-router-dom";
import { useNotify } from "../../util/notify";

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}
interface iDisplayDateProp {
    date: string;
    colourize: boolean
}

export const DisplayDate = ({ date, colourize }: iDisplayDateProp) => {

    const d: Date = new Date(Date.parse(date));
    let isFuture = (d < new Date(Date.now()) ? "red" : "black")
    if (colourize === false) {
        isFuture = 'black'
    }
    if (!isNaN(Number(d))) {
        let date, month, year;

        date = d.getDate();
        month = d.getMonth() + 1; // take care of the month's number here ⚠️
        year = d.getFullYear();

        return <span style={{ color: isFuture }}>{`${date}/${month}/${year}`}</span>

    }
    return <></>
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}




const ServiceSchedule = () => {
    const mounted = useRef(false)
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true)
    const { CompanyHideGasCertificateYN, CompanyHideGasServiceYN, CompanyHideRefrigerationServiceYN, CompanyHideElectricalServiceYN, CompanyHideCoffeeYN } = useAuth();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState('Site');
    const { displayNote } = useNotify();
    useEffect(() => {

        const Go = async () => {
            const response = await doRequest<any>(`reports/sReportServiceSchedules`, 'GET', {})
            console.log(response)
            setData(response);
            setLoading(false)
        }
        if (mounted.current === false) {
            mounted.current = true;

            Go();
        }
    }, []);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = useMemo(
        () => {

            // sort by number
            return data.slice().sort((a, b) => {

                if (a[orderBy] === "" || a[orderBy] === null) return 1;
                if (b[orderBy] === "" || b[orderBy] === null) return -1;
                if (a[orderBy] === b[orderBy]) return 0;
                if (orderBy.includes('Date')) {
                    if (order === 'asc') {
                        return a[orderBy] - b[orderBy]
                    } else {
                        return -(a[orderBy] - b[orderBy])
                    }
                } else {
                    if (order === 'asc') {
                        return a[orderBy] > b[orderBy] ? -1 : 1
                    } else {
                        return -(a[orderBy] > b[orderBy] ? -1 : 1)
                    }

                }

            })


        },
        [order, orderBy, data],
    );



    let gasSize = 0;
    if (CompanyHideGasServiceYN === false && CompanyHideGasCertificateYN === false) {
        gasSize++;
    }
    if (CompanyHideGasServiceYN === false) {
        gasSize++;
        gasSize++;
    } if (CompanyHideGasCertificateYN === false) {
        gasSize++;
        gasSize++;
    }



    const headXS: SxProps = {
        fontWeight: "bold", verticalAlign: "bottom", borderLeft: "1px solid rgba(224, 224, 224, 1)"
    }

    return <Grid container spacing={0}>

        <Grid item {...alwaysFullSpacing}>
            <GreyBox>
                <Grid container>
                    <Grid item md={12}>
                        <h2>Service Schedule</h2>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>

                            {loading === true && <CircularProgress />}
                            <TableContainer sx={{ maxHeight: "70vh" }}>
                                {data.length > 0 && <Table aria-label="Service Schedule" stickyHeader>
                                    <TableHead sx={{ position: 'sticky', top: 0 }}>
                                        <TableRow >
                                            <TableCell colSpan={3}> </TableCell>

                                            {CompanyHideElectricalServiceYN === false && <TableCell sx={headXS} colSpan={3} className="Electric" align="center">Catering Service (Electric) </TableCell>}
                                            {(CompanyHideGasServiceYN === false || CompanyHideGasCertificateYN === false) && <TableCell sx={headXS} colSpan={gasSize} className="Gas" align="center">Catering Service (Gas) </TableCell>}
                                            {CompanyHideRefrigerationServiceYN === false && <TableCell sx={headXS} colSpan={3} className="Fridge" align="center">Refrigeration Service </TableCell>}
                                        </TableRow>
                                        <TableRow>
                                            <ScheduleHeader order={order} orderBy={orderBy} keyRef="Site" title="Site" onRequestSort={handleRequestSort} />

                                            <ScheduleHeader align="center" order={order} orderBy={orderBy} keyRef="Appliances" title="Appliances" onRequestSort={handleRequestSort} />

                                            <ScheduleHeader align="center" order={order} orderBy={orderBy} keyRef="FullPPM" title="Full PPM" onRequestSort={handleRequestSort} />


                                            {CompanyHideElectricalServiceYN === false && <ScheduleHeader order={order} orderBy={orderBy} keyRef="ElectricalPPM" title="PPM Cost" onRequestSort={handleRequestSort} className="Electric" align="center" />}
                                            {CompanyHideElectricalServiceYN === false && <ScheduleHeader order={order} orderBy={orderBy} keyRef="ElectricalDate_Order" title="Last Service" onRequestSort={handleRequestSort} className="Electric" align="center" />}
                                            {CompanyHideElectricalServiceYN === false && <ScheduleHeader order={order} orderBy={orderBy} keyRef="ElectricalNextDate_Order" title="Next Service" onRequestSort={handleRequestSort} className="Electric" align="center" />}


                                            {(CompanyHideGasServiceYN === false && CompanyHideGasCertificateYN === false) && <ScheduleHeader order={order} orderBy={orderBy} keyRef="GasPPM" title="PPM Cost" onRequestSort={handleRequestSort} className="Gas" align="center" />}
                                            {CompanyHideGasServiceYN === false && <ScheduleHeader order={order} orderBy={orderBy} keyRef="GasDate_Order" title="Last Service" onRequestSort={handleRequestSort} className="Gas" align="center" />}
                                            {CompanyHideGasServiceYN === false && <ScheduleHeader order={order} orderBy={orderBy} keyRef="GasNextDate_Order" title="Next Service" onRequestSort={handleRequestSort} className="Gas" align="center" />}
                                            {CompanyHideGasCertificateYN === false && <ScheduleHeader order={order} orderBy={orderBy} keyRef="GasCertificateDate_Order" title="Last Gas Certificate" onRequestSort={handleRequestSort} className="Gas" align="center" />}
                                            {CompanyHideGasCertificateYN === false && <ScheduleHeader order={order} orderBy={orderBy} keyRef="GasCertificateNextDate_Order" title="Gas Certificate Due" onRequestSort={handleRequestSort} className="Gas" align="center" />}


                                            {CompanyHideRefrigerationServiceYN === false && <ScheduleHeader order={order} orderBy={orderBy} keyRef="RefrigerationPPM" title="PPM Cost" onRequestSort={handleRequestSort} className="Fridge" align="center" />}
                                            {CompanyHideRefrigerationServiceYN === false && <ScheduleHeader order={order} orderBy={orderBy} keyRef="RefrigerationDate_Order" title="Last Service" onRequestSort={handleRequestSort} className="Fridge" align="center" />}
                                            {CompanyHideRefrigerationServiceYN === false && <ScheduleHeader order={order} orderBy={orderBy} keyRef="RefrigerationNextDate_Order" title="Next Service" onRequestSort={handleRequestSort} className="Fridge" align="center" />}

                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ overflowX: 'scrol', height: 500 }}>
                                        {visibleRows.map((row) => {

                                            return <TableRow key={row.name} onClick={(ev) => {
                                                ev.preventDefault();
                                                if (row.Appliances && row.Appliances > 0) {
                                                    window.open(`/serviceSchedule/${row.id}`);
                                                } else {
                                                    displayNote&& displayNote('There are no appliances for this location.', 'warning')
                                                }

                                            }}>
                                                <TableCell component="th" scope="row" style={{ width: 460, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }}>
                                                    {row.Site}
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">
                                                    {row.Appliances}
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(255, 255, 255, 20)", borderBottom: "1px solid rgba(255, 255, 255, 20)" }} align="center">

                                                    {row.FullPPM !== null && <>£  {row.FullPPM}</>}
                                                </TableCell>

                                                {CompanyHideElectricalServiceYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Electric">

                                                    {row.ElectricalPPM !== null && <>£  {row.ElectricalPPM}</>}
                                                </TableCell>}
                                                {CompanyHideElectricalServiceYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Electric">

                                                    {typeof row.ElectricalDate === 'string' && <DisplayDate date={row.ElectricalDate} colourize={false} />}

                                                </TableCell>}
                                                {CompanyHideElectricalServiceYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Electric">
                                                    {typeof row.ElectricalNextDate === 'string' && <DisplayDate date={row.ElectricalNextDate} colourize={true} />}

                                                </TableCell>}

                                                {(CompanyHideGasServiceYN === false && CompanyHideGasCertificateYN === false) && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Gas">

                                                    {row.GasPPM !== null && <>£  {row.GasPPM}</>}
                                                </TableCell>}
                                                {CompanyHideGasServiceYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Gas">
                                                    {typeof row.GasDate === 'string' && <DisplayDate date={row.GasDate} colourize={false} />}

                                                </TableCell>}
                                                {CompanyHideGasServiceYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Gas">
                                                    {typeof row.GasNextDate === 'string' && <DisplayDate date={row.GasNextDate} colourize={true} />}

                                                </TableCell>}
                                                {CompanyHideGasCertificateYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Gas">

                                                    {typeof row.GasCertificateDate === 'string' && <DisplayDate date={row.GasCertificateDate} colourize={false} />}

                                                </TableCell>}
                                                {CompanyHideGasCertificateYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Gas">

                                                    {typeof row.GasCertificateNextDate === 'string' && <DisplayDate date={row.GasCertificateNextDate} colourize={true} />}
                                                </TableCell>}



                                                {CompanyHideRefrigerationServiceYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Fridge">
                                                    {row.RefrigerationPPM !== null && <>£  {row.RefrigerationPPM}</>}
                                                </TableCell>}
                                                {CompanyHideRefrigerationServiceYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Fridge">

                                                    {typeof row.RefrigerationDate === 'string' && <DisplayDate date={row.RefrigerationDate} colourize={false} />}

                                                </TableCell>}
                                                {CompanyHideRefrigerationServiceYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Fridge">
                                                    {typeof row.RefrigerationNextDate === 'string' && <DisplayDate date={row.RefrigerationNextDate} colourize={true} />}

                                                </TableCell>}
                                            </TableRow>
                                        })}




                                        <TableRow>
                                            <TableCell component="th" scope="row" style={{ width: 260, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} sx={headXS}>
                                                Total:
                                            </TableCell>
                                            <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center">

                                            </TableCell>
                                            <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} sx={headXS} align="center">
                                                £ {data.reduce((accumulator, currentValue) => {
                                                    return accumulator + currentValue.FullPPM
                                                }, 0)}
                                            </TableCell>
                                            {CompanyHideElectricalServiceYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} sx={headXS} align="center" className="Electric">
                                                £ {data.reduce((accumulator, currentValue) => {
                                                    return accumulator + currentValue.ElectricalPPM
                                                }, 0)}
                                            </TableCell>}
                                            {CompanyHideElectricalServiceYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Electric">


                                            </TableCell>}
                                            {CompanyHideElectricalServiceYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Electric">

                                            </TableCell>}

                                            {(CompanyHideGasServiceYN === false && CompanyHideGasCertificateYN === false) && <TableCell sx={headXS} style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Gas">
                                                £ {data.reduce((accumulator, currentValue) => {
                                                    return accumulator + currentValue.GasPPM
                                                }, 0)}
                                            </TableCell>}
                                            {CompanyHideGasServiceYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Gas">


                                            </TableCell>}
                                            {CompanyHideGasServiceYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Gas">


                                            </TableCell>}
                                            {CompanyHideGasCertificateYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Gas">



                                            </TableCell>}
                                            {CompanyHideGasCertificateYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Gas">


                                            </TableCell>}



                                            {CompanyHideRefrigerationServiceYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} sx={headXS} align="center" className="Fridge">
                                                £ {data.reduce((accumulator, currentValue) => {
                                                    return accumulator + currentValue.RefrigerationPPM
                                                }, 0)}
                                            </TableCell>}
                                            {CompanyHideRefrigerationServiceYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Fridge" sx={headXS}>


                                                {CompanyHideRefrigerationServiceYN === false && <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center" className="Fridge"> </TableCell>}

                                            </TableCell>}



                                        </TableRow>
                                    </TableBody>


                                </Table>}
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>
            </GreyBox>
        </Grid>

    </Grid>
}


export default ServiceSchedule