import { Route, Routes } from "react-router-dom";
import SecureRoute from "../../util/secureRoute";
import Location from "./location";
import Locations from "./locations";


const LocationsRoutes = () => {

    return <Routes>
        <Route path="/" element={<SecureRoute title="Locations"><Locations /></SecureRoute>} />
        <Route path="/:id" element={<SecureRoute title="Company Location"><Location /></SecureRoute>} />
    </Routes>
}


export default LocationsRoutes;