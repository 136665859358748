import { Route, Routes } from "react-router-dom";
import SecureRoute from "../../util/secureRoute";
import Asset from "./asset";
import Assets from "./assets";


const AssetsRoutes = () => {

    return <Routes>
        <Route path="/" element={<SecureRoute title="Assets"><Assets /></SecureRoute>} />
        <Route path="/:id" element={<SecureRoute title="Asset"><Asset /></SecureRoute>} />
    </Routes>
}


export default AssetsRoutes;