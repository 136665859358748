import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { Button, Grid } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { buttonSX } from './buttons';
interface iDisplaySomething {
    field: string;
    text: string;
    payload: any;
    type?: 'text' | 'number' | 'money' | 'date' | 'email' | 'phone' | 'job' | 'time' | 'repair' | 'asset' | 'location' | 'quote' | 'siteName'
    size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

}


const DisplaySomething = ({ field, payload, type = "text", text, size = 6 }: iDisplaySomething) => {

    const navigate = useNavigate();

    let data: any = ''
    console.log(field)

    if (payload[field] !== undefined) {
        const something = payload[field];

        console.log(something)

        switch (type) {
            case 'text':
                if (something && something.split) {
                    const split = something.split('\r\n');


                    data = split.map((d: any) => {
                        return <>{d} <br /></>
                    });

                } else {
                    data = <></>
                }

                break;
            case 'quote':

                const somethingX = payload["JobID"];
                if (parseInt(somethingX) > 0) {
                    data = <Button variant='outlined' onClick={() => {
                        navigate && navigate(`/quote/${somethingX}`, { replace: false })
                    }}>{something}</Button>
                }
                break;
            case 'email':
                data = <> {something} <Button variant='outlined' href={`mailto:${something}`}><EmailIcon /></Button></>;

                break;
            case 'phone':
                data = <> {something} <Button variant='outlined' href={`tel:${something.replace(' ', '')}`}><PhoneIcon /></Button></>;

                break;
            case 'number':
                data = <> {something}</>;

                break;
            case 'siteName':

                const constLocationId = payload['CompanyLocationID'];
                // if (parseInt(something) > 0) {
                //     data = <Button variant='outlined' onClick={() => {
                //         navigate && navigate(`/locations/${something}`, { replace: false })
                //     }}>{something}</Button>
                // }

                data = <Button sx={buttonSX} variant='outlined' onClick={() => {
                    navigate && navigate(`/locations/${constLocationId}`, { replace: false })
                }}>{something}</Button>


                break;
            case 'job':
                if (parseInt(something) > 0) {
                    data = <Button  sx={buttonSX} variant='outlined' href={`/jobs/${something}`}>{something}</Button>
                }
                break;
            case 'location':
                if (parseInt(something) > 0) {
                    data = <Button  sx={buttonSX} variant='outlined' href={`/locations/${something}`}>{something}</Button>
                }

                break;
            case 'repair':
                const jobID = (payload['JobID'] ? payload['JobID'] : 'no field');
                data = <Button  sx={buttonSX} variant='outlined'  href={`/jobs/${jobID}`}>{something}</Button>



                break;
            case 'asset':
                if (parseInt(something) > 0) {
                    data = <Button  sx={buttonSX} variant='outlined' href={`/assets/${something}`}>{something}</Button>
                }


                break;
            case 'money':

                data = <>£ {something.toFixed(2)}</>



                break;
            case 'date':


                const d: Date = new Date(Date.parse(something));

                if (!isNaN(Number(d))) {
                    let date, month, year;

                    date = d.getDate();
                    month = d.getMonth() + 1; // take care of the month's number here ⚠️
                    year = d.getFullYear();

                    data = <>{`${date}/${month}/${year}`}</>

                }

                break;
            case 'time':

                const addZero = (i: any) => {
                    if (i < 10) { i = "0" + i }
                    return i;
                }
                const d2: Date = new Date(Date.parse(something));




                if (!isNaN(Number(d2))) {
                    let date, month, year, hours, min;

                    date = d2.getDate();
                    month = d2.getMonth() + 1; // take care of the month's number here ⚠️
                    year = d2.getFullYear();
                    hours = addZero(d2.getHours());
                    min = addZero(d2.getUTCMinutes());
                    data = <>{`${hours}:${min}  (${date}/${month}/${year})`}</>

                }

                break;
        }
    } else {

        data = <>sd</>
    }


    return <Grid item container lg={size} md={size} sm={size} xs={size}>

        <Grid item md={4} sm={4} xs={4} sx={{ padding: "1em" }}>{text}</Grid>
        <Grid item md={8} sm={8} xs={8} sx={{ padding: "1em", textAlign: 'right' }} >{data}</Grid>

    </Grid>
}


export default DisplaySomething