import { Grid, TextField } from "@mui/material";
import { Field, FormSpy } from "react-final-form";
import { composeValidators, mustBePassword, requiredValidate } from "./validators";

interface iTextInput {
    name: string;
    label: string;
    type?: string;
    ml?: boolean;
    required?: boolean;
}

const TextInputField = ({ name, label, type = 'string', required = false, ml = false }: iTextInput) => {

    //  const ml = multiline;


    let fieldProps: any = {}

    if (type === 'password') {
        console.log("pass");
        if (required) {
            fieldProps.validate = composeValidators(requiredValidate, mustBePassword)
        }
    }

    return <Grid item xs={12}>
        <FormSpy
            subscription={{
                values: true,
                dirtySinceLastSubmit: true,
                submitting: true,
            }}
        >
            {({ dirtySinceLastSubmit, submitting }) => (



                <Field name={name} {...fieldProps}>
                    {({ input, meta }) => {
                        return <>
                            {required === true ? <TextField
                                {...input}
                                // autoComplete={(type === 'password' ? 'off' : 'new-password')}
                                
                                name={name}
                                label={label}
                                fullWidth
                                type={type}
                                multiline={ml}
                                required
                                variant="outlined"
                                color="secondary"
                                error={
                                    (meta.error && meta.touched) ||
                                    (!!meta.submitError &&
                                        !dirtySinceLastSubmit &&
                                        !submitting)
                                }
                                helperText={
                                    meta.error && meta.touched
                                        ? meta.error
                                        : !!meta.submitError &&
                                            !dirtySinceLastSubmit &&
                                            !submitting
                                            ? meta.submitError
                                            : ""
                                }
                            /> : <TextField
                                {...input}
                                name={name}
                                label={label}
                                fullWidth
                                autoComplete="off"
                                // autoComplete={(type === 'password' ? 'off' : 'new-password')}
                                type={type}
                                multiline={ml}
                                variant="outlined"
                                color="secondary"
                                error={
                                    (meta.error && meta.touched) ||
                                    (!!meta.submitError &&
                                        !dirtySinceLastSubmit &&
                                        !submitting)
                                }
                                helperText={
                                    meta.error && meta.touched
                                        ? meta.error
                                        : !!meta.submitError &&
                                            !dirtySinceLastSubmit &&
                                            !submitting
                                            ? meta.submitError
                                            : ""
                                }
                            />}


                        </>
                    }}
                </Field>
            )}
        </FormSpy>
    </Grid>
}


export default TextInputField;