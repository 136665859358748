import { Grid } from "@mui/material";
import GreyBox from "../../util/greyBox";
import SqlTableMagic from "../../util/sqlTableMagic";
const Home = () => {


    return <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
            <GreyBox>
                <SqlTableMagic title="Live Quotes" url="quotes/Home" requestType='GET' /></GreyBox>

        </Grid>
        <Grid item xs={12} md={12}> <GreyBox>
            <SqlTableMagic title="Live Jobs" url="jobs/sHomeLiveJob" requestType='GET' />
        </GreyBox>
        </Grid>
        <Grid item xs={12} md={12}> <GreyBox>
            <SqlTableMagic title="Complete Jobs" url="jobs/sHomeCompleteJob" requestType='GET' />
        </GreyBox>
        </Grid>

    </Grid >
}


export default Home