import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import GreyBox, { alwaysFullSpacing } from "../../util/greyBox";
import SqlTableMagic from "../../util/sqlTableMagic";

const Jobs = () => {

    const [query, setQuery] = useState<any | undefined>({ StatusTypeFilter: "Live" });

    return <Grid container spacing={0}>
        <Grid item  {...alwaysFullSpacing}>
            <GreyBox>
                <Grid container>

                    <Grid item md={2}>
                        <h2>Jobs</h2>
                    </Grid>
                    <Grid item md={10}>
                        <Box sx={{ textAlign: 'right', paddingTop: 2 }}>
                            <Button onClick={() => {
                                setQuery(undefined)
                            }} className={`${query === undefined && 'activeButton'}`}>
                                All
                            </Button>

                            <Button onClick={() => {
                                setQuery({ StatusTypeFilter: "Live" })
                            }} className={`${query !== undefined && query.StatusTypeFilter === "Live" && 'activeButton'}`}>
                                Live
                            </Button>

                            <Button onClick={() => {
                                setQuery({ StatusTypeFilter: "Complete" })
                            }} className={`${query !== undefined && query.StatusTypeFilter === "Complete" && 'activeButton'}`}>
                                Complete
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <SqlTableMagic title="" url="jobs/list" requestType='GET' height="700px" query={query} />
            </GreyBox>
        </Grid>
    </Grid>
}

export default Jobs