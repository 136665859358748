import { Box, Button, ButtonGroup, Card, CardActions, CardContent, CardMedia, IconButton, Modal, Typography } from "@mui/material";
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useRef, useState } from "react";
import Download from "./download";
import { useNotify } from "./notify";
import DownloadIcon from '@mui/icons-material/Download';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
interface iCacheThumnail {
    title: string;
    cacheId: any;

}
const blobToBase64 = (blob: any) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: "90%",
    width: "75%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: "scroll",
    p: 4,
};

const CacheThumnail = ({ title, cacheId }: iCacheThumnail) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // const isArray = Array.isArray(jobId)
    const { displayNote } = useNotify();
    const [loaded, setLoaded] = useState(false);
    const [thumb, setThumb] = useState("");
    const Go = async () => {


        console.log("GET", cacheId)

        axios({
            url: `/api/cache/${cacheId}`,
            method: 'GET',
            responseType: 'blob',
        }).then((response: any) => {

            console.log("GET", response.data)
            if (response.data.size > 100) {
                const ffsWHy = response.headers['content-disposition'].split('"')[1]
                console.log("GET", response.headers['content-disposition'], response.data, ffsWHy)
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', ffsWHy); //or any other extension
                document.body.appendChild(link);
                link.click();

            } else {
                displayNote && displayNote("Cant Find Cache", 'error');
            }

        }).catch((ex) => {

        });

    }

    const mounted = useRef(false)
    useEffect(() => {
        if (mounted.current === false) {
            mounted.current = true;

            console.log("GET", cacheId)
            if (cacheId !== null && cacheId !== 'null') {
                axios({
                    url: `/api/cache/${cacheId}?thumb=true`,
                    method: 'GET',
                    responseType: 'blob',   
                }).then((response: any) => {
                    console.log(response, response.data)
                    const blob = new Blob([response.data])




                    blobToBase64(blob).then((val: any) => {
                        console.log("", val)
                        setThumb(val);
                        setLoaded(true)
                    })


                }).catch((ex) => {

                });
            }
        }

    }, [])


    return <>{cacheId !== null && cacheId !== 'null' && loaded === true && <>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <ButtonGroup sx={{ position: 'absolute', right: 20 }}>

                    <IconButton
                        onClick={(ev) => {
                            ev.preventDefault();
                            handleClose()
                        }}
                        aria-label={`Close`}
                    >
                        <CloseIcon />
                    </IconButton>
                    <IconButton
                        onClick={(ev) => {
                            ev.preventDefault();
                            cacheId && Download(cacheId);
                        }}
                        aria-label={`Download`}
                    >
                        <DownloadIcon />
                    </IconButton>
                </ButtonGroup>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {title}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: "center" }}>
                    <Box
                        component="img"
                        sx={{
                            width: "80%",
                            //  maxHeight: { xs: 233, md: 167 },
                            //   maxWidth: { xs: 350, md: 250 },
                        }}
                        alt={title}
                        src={`/api/cache/${cacheId}`}
                    />
                </Typography>
            </Box>
        </Modal>
        <Typography variant="h5" gutterBottom sx={{
            paddingLeft: 1
        }}>
            {title}
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
            {thumb !== '' && <><Box
                onClick={() => {
                    //  ex.preventDefault();
                    handleOpen();
                }}
                component="img"
                sx={{
                    width: "90%",
                    //  maxHeight: { xs: 233, md: 167 },
                    //   maxWidth: { xs: 350, md: 250 },
                }}
                alt={title}
                src={thumb}
            />
                <ButtonGroup>
                    <IconButton type="button" onClick={(ex) => {
                        ex.preventDefault();
                        Go();
                    }}>
                        <DownloadIcon />
                    </IconButton>
                    <IconButton type="button" onClick={(ex) => {
                        ex.preventDefault();
                        handleOpen();
                    }}>
                        <ZoomInIcon />
                    </IconButton>
                </ButtonGroup></>}

        </Box>

    </>}

    </>
}


export default CacheThumnail;