import { Route, Routes } from "react-router-dom";
import InsecureRoute from "../../util/insecureRoute";
import SecureRoute from "../../util/secureRoute";
import Home from "./home";
import Legal from "./legal";
import Profile from "./profile";



const DashRoutes = () => {

    return <Routes>
        <Route path="/" element={<SecureRoute title="Home"><Home /></SecureRoute>} />
        <Route path="/profile" element={<SecureRoute title="Profile"><Profile /></SecureRoute>} />
        <Route path="/legal" element={<InsecureRoute title="Legal"><Legal /></InsecureRoute>} />
    </Routes>
}


export default DashRoutes;