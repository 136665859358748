import { Box, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import doRequest from '../../util/axios';
import TextInputField from '../../util/fields/text-field';
import FormContainer from '../../util/form-container';
import { useNotify } from '../../util/notify';
interface dummyResponse {
    ok: boolean,
    d: any,
    // msg?: string,
}
const ForgotPassword = () => {
    const { displayNote } = useNotify();
    const [d, setD] = useState<boolean>(false)

    return <Grid container> <Grid item lg={3} md={3} sm={3} />
        <Grid md={6} item sx={{ paddingTop: 4 }}>
            {d === false ? <FormContainer onSubmit={async ({ email, }) => {
                const response = await doRequest<dummyResponse>('auth/forgot-password', "POST", { email })
                console.log("forgot-password", response)
                if (response && response.ok === true) {
                    setD(true);
                } else {
                    if (response && response.d) {
                        displayNote && displayNote(response.d, "error");
                    } else {
                        displayNote && displayNote("Something was not right there, please try again.", "success")
                    }
                }
            }}
                requiredFields={['email']}
                buttonText="Recover Account"
                additionalButtons={<Box sx={{ textAlign: 'center', width: "100%" }} > <Link style={{ textAlign: "center" }} to="/login">Back to Login</Link> <br /> <a className='btn btn-link' href="https://www.airevalleycatering.com/portalregistration" target={"_new"}>Register</a></Box>}
            >    <Box sx={{ textAlign: 'center', width: "100%", display: { xs: 'none', sm: 'none', md: 'block' } }}><img alt="logo" src="/Airevalley-Transparent-large-1.png" /></Box>
                <TextInputField name="email" label="Email" type="email" />
            </FormContainer> : <Typography>Please check your email to recover your account</Typography>
            }
        </Grid >
    </Grid >

}


export default ForgotPassword