

export const requiredValidate = (value: any) => (value ? undefined : 'Required')
export const mustBeEmail = (value: string) => {

    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const result = re.test(String(value).toLowerCase());
    return (result === true ? undefined : 'Must be a valid email address')
}
export const mustBePassword = (value: string) => {

    const re = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;
    const result = re.test(String(value));
    return (result === true ? undefined : 'Must be a valid password.(min 8 letter password, with at least a symbol, upper and lower case letters and a number')
}
export const mustBeURLSafe = (value: string) => {

    const re = /^[a-zA-Z0-9_-]*$/i;
    const result = re.test(String(value).toLowerCase());
    return (result === true ? undefined : 'Must be a url safe string')
}

export const mustBeNumber = (value: any) => (isNaN(value) ? 'Must be a number' : undefined)
export const minValueValidate = (min: number) => (value: any) => isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
export const maxValueValidate = (max: number) => (value: any) => isNaN(value) || value <= max ? undefined : `Should be less than ${max}`
export const composeValidators = (...validators: any[]) => (value: any) => validators.reduce((error, validator) => error || validator(value), undefined)
