import { Alert, Box, InputLabel } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import doRequest from '../../util/axios';
import { useAuth } from '../../util/context';

import FormContainer from '../../util/form-container';
import { useNotify } from '../../util/notify';
import { composeValidators, mustBePassword, requiredValidate } from '../../util/fields/validators';

import { Grid, TextField } from "@mui/material";
import { Field, FormSpy } from "react-final-form";
import { useState } from 'react';







interface iLoginTextInput {
    name: string;
    label: string;
    type?: string;
    ml?: boolean;
    required?: boolean;
}

const LoginTextInputField = ({ name, label, type = 'string', required = false, ml = false }: iLoginTextInput) => {

    //  const ml = multiline;


    let fieldProps: any = {}

    if (type === 'password') {
        console.log("pass");
        if (required) {
            fieldProps.validate = composeValidators(requiredValidate, mustBePassword)
        }
    }

    return <Grid item xs={12}>
        <FormSpy
            subscription={{
                values: true,
                dirtySinceLastSubmit: true,
                submitting: true,
            }}
        >
            {({ dirtySinceLastSubmit, submitting }) => (

                <Field name={name} {...fieldProps}>
                    {({ input, meta }) => {
                        return <>
                            {required === true ? <>
                                <InputLabel disableAnimation={true}>{label}</InputLabel>
                                <TextField
                                    {...input}
                                    autoComplete={(type === 'password' ? 'off' : 'new-password')}

                                    name={name}

                                    fullWidth
                                    type={type}
                                    multiline={ml}
                                    required
                                    variant="outlined"
                                    color="secondary"
                                    error={
                                        (meta.error && meta.touched) ||
                                        (!!meta.submitError &&
                                            !dirtySinceLastSubmit &&
                                            !submitting)
                                    }
                                    helperText={
                                        meta.error && meta.touched
                                            ? meta.error
                                            : !!meta.submitError &&
                                                !dirtySinceLastSubmit &&
                                                !submitting
                                                ? meta.submitError
                                                : ""
                                    }
                                /></> : <>
                                <InputLabel disableAnimation={true}>{label}</InputLabel><TextField
                                    {...input}
                                    name={name}

                                    fullWidth

                                    autoComplete={(type === 'password' ? 'off' : 'new-password')}
                                    type={type}
                                    multiline={ml}
                                    variant="outlined"
                                    color="secondary"
                                    error={
                                        (meta.error && meta.touched) ||
                                        (!!meta.submitError &&
                                            !dirtySinceLastSubmit &&
                                            !submitting)
                                    }
                                    helperText={
                                        meta.error && meta.touched
                                            ? meta.error
                                            : !!meta.submitError &&
                                                !dirtySinceLastSubmit &&
                                                !submitting
                                                ? meta.submitError
                                                : ""
                                    }
                                /></>}


                        </>
                    }}
                </Field>
            )}
        </FormSpy>
    </Grid>
}













interface dummyResponse {
    ok: boolean,
    d: any
}
const Login = () => {
    const { displayNote } = useNotify();
    const auth = useAuth();
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    return <Grid container>
        <Grid item lg={3} md={3} sm={3} />
        <Grid md={6} item sx={{ paddingTop: 2 }}>


            <FormContainer onSubmit={async ({ email, password, rememberMe }) => {
                //    await doLogin(email, password, rememberMe)
                const response = await doRequest<dummyResponse>('auth/login', "POST", { email, password })
                console.log("logisdsdsdn", response, location.search)

                if (response) {
                    if (response.ok === true) {
                        auth.logIn && await auth.logIn() 
                        let redirectTo: string = "/";

                        if (location.search) redirectTo = location.search.replace('?returnTo=', '')
                        setTimeout(() => {

                            navigate(redirectTo, { replace: true })
                        }, 200);
                    } else {
                        setMessage("The details you have entered do not match. Please try again.")
                    }

                }
            }}
                requiredFields={['email', 'password']}
                buttonText="Login"
                additionalButtons={
                    <Box sx={{ textAlign: 'center', width: "100%", paddingTop: 2 }}><Link style={{ textAlign: "center" }} to="/login/forgot-password">Reset Password</Link>
                        <br /><br /><br /> <a className='btn btn-link' href="https://www.airevalleycatering.com/portalregistration" target={"_new"}>Register</a></Box>}

            >
                <Box sx={{ textAlign: 'center', width: "100%", display: { xs: 'none', sm: 'none', md: 'block' } }}><img alt="logo" src="/Airevalley-Transparent-large-1.png" /></Box>

                {message !== '' && <Box sx={{ textAlign: 'center', width: "100%", paddingTop: 2, paddingLeft: 3, paddingRight: 1 }}><Alert severity="error" >
                    {message} </Alert></Box>}
                <LoginTextInputField name="email" label="Email" type="email" />
                <LoginTextInputField name="password" label="Password" type="password" />


            </FormContainer></Grid ></Grid >

}


export default Login