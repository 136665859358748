import { Logout } from '@mui/icons-material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MenuIcon from '@mui/icons-material/Menu';
import PhoneIcon from '@mui/icons-material/Phone';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from "./context";
import { NotifyDisplay } from './notify';

const pages = ["Home", "Quotes", 'Jobs', 'Locations', 'Assets', 'Service Schedule', "Spend"];
const links = ['/', '/quote', '/jobs', '/locations', '/assets', '/serviceSchedule', '/spend']
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Nav = () => {
    const auth = useAuth();
    const location = useLocation();

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    return <>


        <AppBar
            elevation={0}
            position="fixed"
            style={{
                width: "100%",
                zIndex: 999,
                backgroundColor: "#fff",
                color: '#000',
                display: "flex",
                alignItems: "center",
            }}

            sx={{
                paddingLeft: 0,
                paddingRight: 0,
            }}
        >
            <Container className="noPadMargin" maxWidth={false} sx={{

            }}>
                <Box className="navTop" sx={{
                    background: '#919191', width: "100%", paddingLeft: 0, color: "white", paddingBottom: 1,
                    paddingRight: 0,
                }}>
                    <PhoneIcon /> 01904 607048
                    <LocationOnIcon /> Suite 4 and 5, Woodhouse Grange Business Centre, York, YO41 4DF
                </Box>
                {!location.pathname.startsWith("/login") &&
                <Toolbar disableGutters>
                {auth.loggedIn === true  && !location.pathname.startsWith("/login") && <Link to="/">
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >  <Link to={'/'}>
                                <img alt="logo" src="/Airevalley-Transparent-small-1.png" />
                            </Link>
                        </Typography>
                    </Link>}
                    
                    {!location.pathname.startsWith("/login") && <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        {auth.loggedIn === true && <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>}
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {auth.loggedIn === true && pages.map((page, iPage) => {
                                const link = links[iPage];


                                return <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Link to={link} className="burger-button">
                                        <Typography textAlign="center">{page}</Typography></Link>
                                </MenuItem>
                            })}
                        </Menu>
                    </Box>}
                    
                    {auth.loggedIn === true && !location.pathname.startsWith("/login") && <Link to="/">
                        <Typography
                            variant="h5"
                            noWrap
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            <img src="/favicon-32x32.png" alt="logo" />
                        </Typography>
                    </Link>}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {auth.loggedIn === true && pages.map((page, iPage) => {
                            const link = links[iPage];
                            let active = false;
                            console.log(location.pathname);


                            if ((location.pathname === link) && link === '/') {
                                active = true;
                            } else {
                                if ((location.pathname.includes(link)) && link !== '/') {
                                    active = true;
                                }
                            }


                            return <Link key={page}
                                onClick={handleCloseNavMenu} className={`navLink ${active && 'navLinkActive'}`} to={link}>{page}</Link>

                        })}
                    </Box>
                 
                    {auth.loggedIn === true && !location.pathname.startsWith("/login") && <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <><Box sx={{ display: 'inline-block', paddingRight: 2, fontWeight: 800 }}>{auth.company}</Box>
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt={`${auth && auth.name}`} />
                                </IconButton>
                            </>

                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem>

                                <Typography textAlign="center">
                                    {auth && auth.name}
                                </Typography>


                            </MenuItem>

                            <MenuItem onClick={handleCloseUserMenu}>

                                <Link color='inherit' to={`/profile`}>
                                    <Typography textAlign="center">
                                        Profile
                                    </Typography>

                                </Link>

                            </MenuItem>

                            {auth.loggedIn === true && <MenuItem onClick={handleCloseUserMenu}>
                                <IconButton color='inherit' onClick={() => {
                                    auth && auth.logOut && auth.logOut && auth.logOut();
                                }}>
                                    <Typography textAlign="center">
                                        <Logout
                                            style={{
                                                // transform: "scale(1.4)",
                                            }}
                                        />
                                    </Typography>


                                </IconButton>

                            </MenuItem>}


                        </Menu>

                    </Box>}
                </Toolbar>}
            </Container>
        </AppBar>

        <NotifyDisplay />
    </>



}

export default Nav;