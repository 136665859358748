import { Grid } from "@mui/material";
import GreyBox, { alwaysFullSpacing } from "../../util/greyBox";
import SqlTableMagic from "../../util/sqlTableMagic";
const Assets = () => {



    return <Grid container spacing={0}>
        <Grid item {...alwaysFullSpacing}>
            <GreyBox>
                <Grid container>

                    <Grid item md={2}>
                        <h2>Assets</h2>
                    </Grid>

                </Grid>

                <SqlTableMagic title="" url="assets/list" requestType='GET' height="700px" />

            </GreyBox>
        </Grid>

    </Grid>
}


export default Assets