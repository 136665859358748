import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useReducer, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import doRequest from "../../util/axios";
import DataDump from "../../util/dataDump";
import DisplaySomething from "../../util/displaySomething";
import NotFound from "../../util/not-found";
const Repair = () => {
    const params = useParams();
    const [repair, setRepair] = useState<any>();
    const mounted = useRef(false)
    useEffect(() => {
        const Go = async () => {
            const response = await doRequest<any>(`repair/repair/${params.id}`, 'GET', {})
            console.log("static", response)
            if (response.length !== 0) {
                setRepair(response[0]);
            } else {
                setRepair(null)
            }
        }

        if(mounted.current === false){
            mounted.current = true;
            Go();
        }
   
    }, [params.id])


    if (repair === undefined) {
        return <CircularProgress />
    }
    if (repair === null) {
        return <NotFound />
    }
    return <Grid container spacing={2}>


        <DisplaySomething payload={repair} field="JobApplianceRepairDate" text="Repair Date" type="date" />
        <DisplaySomething payload={repair} field="JobApplianceRepairID" text="JobApplianceRepairID" />
        <DisplaySomething payload={repair} field="JobID" text="JobID" type="job" />
        <DisplaySomething payload={repair} field="ApplianceID" text="ApplianceID" type="asset" />

        <DisplaySomething payload={repair} field="JobApplianceNote" text="Note" />
        <DisplaySomething payload={repair} field="JobAppliancePartsUsed" text="Appliance Parts Used" />
        <DisplaySomething payload={repair} field="JobAppliancePartsRequired" text="JobAppliancePartsRequired" />
        <DisplaySomething payload={repair} field="JobApplianceRepairIncome" text="Repair Income" />
        <DisplaySomething payload={repair} field="ApplianceType" text="Type" />
        <DisplaySomething payload={repair} field="ApplianceDescription" text="Description" />
        <DisplaySomething payload={repair} field="ApplianceAssetNumber" text="Asset No" />
        <DisplaySomething payload={repair} field="ApplianceManufacturer" text="Manufacturer" />
        <DisplaySomething payload={repair} field="ApplianceModel" text="Model" />
        <DisplaySomething payload={repair} field="ApplianceSerialNumber" text="Serial No" />




        <Grid item md={12}>
            <DataDump data={repair} />
        </Grid>


    </Grid>
}


export default Repair