import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const MissingRows = () => {
    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'

    }}>

        <Box sx={{ margin: "auto", paddingTop: 20 }}>
            <img alt="logo" src="/Airevalley-Transparent-small-1.png" />

            <Typography variant="h5">Nothing to see here</Typography>
            <Typography>We don’t have any information for this table.</Typography>

        </Box>

    </Box>
}


export default MissingRows;