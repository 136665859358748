import { Paper } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Link } from 'react-router-dom';
import packageJson from '../../package.json';
import { useAuth } from "./context";
import Nav from "./nav";
import DebugButton from "./debugButton";
interface iPageWrapper {
    title: string,
    children: React.ReactNode,
}
const PageWrapper = ({ title, children, }: iPageWrapper) => {
    const { __VERSION } = useAuth()
    return <div style={{ display: "flex" }}>
        <Helmet>
            <title>AVC / {title || ""}</title>
        </Helmet>

        <Nav />

        <main id="page-container"
            style={{
                flexGrow: 1,
                position: "relative",
                width: "100%",
                margin: "125px auto",

                padding: "0 3px",
            }}
        >
            {children}
        </main>

        <Paper sx={{
            marginTop: 'calc(50% + 3vh)',
            width: '100%'

        }} component="footer" square variant="elevation">

            <Link to="/legal">Legal</Link> <br />

            avc-web.api.{__VERSION && __VERSION} avc-web.ui.{packageJson.version}
        </Paper>

    </div>
}

export default PageWrapper;