import { Route, Routes } from "react-router-dom";
import SecureRoute from "../../util/secureRoute";


import ServiceSchedule from "./schedule";
import SiteServiceSchedule from "./site-schedule";


const ServiceRoutes = () => {

    return <Routes>
        <Route path="/" element={<SecureRoute title="Service Schedule"><ServiceSchedule /></SecureRoute>} />
        <Route path="/:id" element={<SecureRoute title="Site Service Schedule"><SiteServiceSchedule /></SecureRoute>} />
    </Routes>
}


export default ServiceRoutes;