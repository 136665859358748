import { Box, Typography } from "@mui/material";
const NotFound = () => {


    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'

    }}>

        <Box sx={{ margin: "auto", paddingTop: 20 }}>
            <img src="/Airevalley-Transparent-small-1.png" alt="logo" />

            <Typography variant="h5">404</Typography>
            <Typography>Sorry cant find that.</Typography>

        </Box>

    </Box>
}

export default NotFound;