
import { Navigate } from "react-router-dom";
import { useAuth } from "./context";
import { iInsecureRoute } from "./insecureRoute";
import PageWrapper from "./pageWrapper";


interface iSecureRoute extends iInsecureRoute {

}
const SecureRoute = ({ title, children }: iSecureRoute) => {
    const auth = useAuth();
    const redirectPath: string = `/login?returnTo=${window.location.pathname}`;
    if (auth && auth.loggedIn === true) {
        return <PageWrapper title={title}>{children}</PageWrapper>
    } else {

        return <Navigate to={redirectPath} replace />;
    }
}

export default SecureRoute