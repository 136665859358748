import axios from "axios";

const doRequest = async <T,>(target: string, method: 'POST' | 'GET', payload?: any, onFail?: () => void,) => {
    try {
        try {
            switch (method) {
                case 'POST':
                    const r = await axios.post(`/api/${target}`, payload);
                    //          console.log(r.data)
                    const response: T = r.data;


                 //   console.log(response)
                    return response;


                case "GET":

                    const rg = await axios.get(`/api/${target}`);
                    //          console.log(r.data)
                    const responseG: T = rg.data;
                //   console.log(responseG)
                    return responseG;



                default:
                    return undefined;
            }
        } catch (ex: any) {
           // console.log(ex);
            if (ex.response.status === 500 || ex.response.status === 504) {

             //    console.log(window.location)
                const hostname = window.location.origin;
                window.location.href = `${hostname}/login?returnTo=${window.location.pathname}`
            }
            return undefined
        }





    } catch (exc) {
        console.log("axios error", exc)
        return undefined;
    }

}


export default doRequest;