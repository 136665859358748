import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid, GridCallbackDetails, GridColDef, GridEventListener, GridMenuIcon, GridRenderCellParams, GridRowParams, GridSelectionModel, GridToolbarContainer, GRID_CHECKBOX_SELECTION_COL_DEF, MuiEvent } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { tableSchemeAndData } from "../interfaces/tableAndSchema";
import Approve from './approve';
import doRequest from './axios';
import BurgerMenu from './burger-menu';
import { useAuth } from './context';
import DebugButton from "./debugButton";
import Decline from './decline';
import Download from './download';
import ErrorBoundary from './error-boundry';
import MissingRows from './missing-rows';
import { useTableContext } from './table-provider';
import TableSearch from './table-search';


export interface iSimpleDialogProps {
    open: boolean;
    note: string | undefined;
    onCancel: () => void;
    onClose: (value: string) => void;
}

const SimpleDialog = (props: iSimpleDialogProps) => {
    const { onClose, onCancel, open, note } = props;

    const handleClose = () => {

        onClose(text);

    };

    const [text, setText] = useState('');
    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setText(event.target.value);
    // };


    return (
        <Dialog onClose={handleClose} fullWidth open={open}>
            <DialogTitle> Note Details</DialogTitle>
            <DialogContent>
                {note && note !== '' && note.split("\n").map(function (item, idx) {
                    return (
                        <span key={idx}>
                            {item}
                            <br />
                        </span>
                    )
                })}



            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Close</Button>

            </DialogActions>

        </Dialog>
    );
}
interface iDoWith {
    title: string,
    func: string
}
interface iSqlTableMagic {
    url: string,
    combinedSearch?: boolean;
    title: string,
    requestType: 'GET' | 'POST'
    height?: string;
    doWithMulti?: iDoWith[]
    // 
    query?: any;
    internalQuery?: boolean
}

interface iToolBar {
    combinedSearch: boolean;
    selectionModel?: GridSelectionModel[];
    doWithMulti?: iDoWith[],
    data?: any[]
    search: string;
    setSearch: (d: string) => void;
}

const CustomToolbar = ({ combinedSearch, selectionModel = undefined, data = undefined, doWithMulti = undefined, search, setSearch }: iToolBar) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const whyModal: number[] = (selectionModel !== undefined ? selectionModel.map((d: any) => d) : [])
    return (
        <GridToolbarContainer sx={{ textAlign: 'right' }}>
            {/* {selectionModel.map((d, di) => {
                const da = data.findIndex(dq => dq.id == d)
                const thing = data[da]
                return <div key={di}>{JSON.stringify(thing)}</div>
            })} */}
            

            {combinedSearch && <TableSearch setSearch={setSearch} />
            }

            {selectionModel && selectionModel.length > 0 && <>



                <Button

                    id="basic-button"
                    className="burger-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <GridMenuIcon /> {selectionModel && selectionModel.length}
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {doWithMulti && doWithMulti.map((d, di) => {


                        return <MenuItem key={di} onClick={handleClose}>

                            <ListItemIcon>

                            </ListItemIcon>
                            <ListItemText>
                                Not Implimented
                            </ListItemText>
                        </MenuItem>



                    })}

                </Menu></>}
        </GridToolbarContainer >
    );
}



const SqlTableMagic = ({ combinedSearch = true, title, url, requestType, height, doWithMulti, query }: iSqlTableMagic) => {


    const [query1, setQuery1] = useState<any | undefined>();


    const multi = (doWithMulti === undefined ? false : true)
    const { triggerUrl, trigger } = useTableContext()
    const navigate = useNavigate();
    const [outputPayload, setOutputPayload] = useState<any[]>([])
    const { logOut } = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const [columns, setColumns] = useState<GridColDef[]>()
    const [serchCols, setSearchCols] = useState<string[]>([])
    const [tns, setTNS] = useState<tableSchemeAndData>();
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [error, setError] = useState<boolean>(false);

    const [pageSize, setPageSize] = useState(10);
    const [note, setNote] = useState<string>()
    const [open, setOpen] = useState(false);
    const handleOpen = (note: string) => {
        setNote(note)
        setOpen(true);
    }
    const handleClose = () => {
        setNote('')
        setOpen(false)
    };
    const ReloadTable = async () => {
        console.log("Reload Table")
        await Go(true)
    }
    const mounted = useRef(false);

    const Go = async (partial = false) => {


        if (partial === false) {
            setLoading(true)
        }

        if (mounted.current === false || partial === true) {
            mounted.current = true;

            try {
                const response = await doRequest<tableSchemeAndData>(url, requestType,)
                console.log("jobs", response)

                if (response === undefined) {
                    setError(true);
                    setLoading(false)
                } else {
                    if (response && response.schema[0] && response.schema[0].X && (response.schema[0].X === 'FAIL' || response.schema[0].X === "TIMEND")) {
                        logOut && logOut();
                        navigate && navigate('/login')
                    } else {

                        if (response && response.schema && response.data) {
                            if (partial === false) {
                                const searchCols: string[] = [];


                                const burgers = response.schema.filter(d => d.T === 'BURGER')

                                console.log(burgers)
                                let xcolumns: GridColDef[] = [];
                                if (multi === true) {
                                    xcolumns.push({
                                        ...GRID_CHECKBOX_SELECTION_COL_DEF,

                                        flex: 1, minWidth: 100
                                    })
                                }
                                if (burgers.length > 0) {
                                    xcolumns.push({
                                        field: 'na', headerName: '', sortable: false, flex: 0.75, renderCell: (params: GridRenderCellParams<string>) => {
                                            return <BurgerMenu actions={burgers} row={params.row} onClose={ReloadTable} />;
                                        },
                                    })
                                }

                                const added: string[] = [];
                                xcolumns.push(...response.schema.filter(d => d.T !== 'BURGER' && d.T !== 'OPENROW').map((d, dI) => {
                                    let Field: string = d.F;
                                    let actualField: string = d.F;
                                    let flex: number = d.W;
                                    let minWidth = flex * 100;
                                    //     flex = 1;
                                    const currentMatches = added.filter(dd => dd === Field);

                                    added.push(Field)

                                    if (currentMatches.length > 0) {
                                        Field = Field + currentMatches.length;
                                    }

                                    // console.log("FUCK SAKE", flex, Field, currentMatches.length)
                                    switch (d.T) {
                                        case 'BUTTONFIELD':
                                            searchCols.push(d.F);
                                            //   console.log(d)
                                            return {
                                                field: Field, minWidth: minWidth, sortable: true, type: 'string', disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams<string>) => {


                                                    let Content = params.row[actualField];

                                                    let link = '';
                                                    if (d.LF === 'OPENJOB') {
                                                        link = `/jobs/${params.row["JobID"]}`
                                                    }
                                                    if (d.LF === 'OPENREPAIR') {
                                                        const jobID = (params.row['JobID'] ? params.row['JobID'] : 'no field');
                                                        link = `/jobs/${jobID}`
                                                    }
                                                    if (d.LF === 'OPENQUOTE') {
                                                        link = `/quote/${params.row["JobID"]}`
                                                    }
                                                    if (d.LF === 'OPENAPPLIANCE') {
                                                        link = `/assets/${params.row["ApplianceID"]}`
                                                    }
                                                    if (d.LF === 'OPENLOCATION') {
                                                        link = `/locations/${params.row["CompanyLocationID"]}`
                                                    }
                                                    if (Content === 0) {
                                                        return '';
                                                    } else {
                                                        return <Link to={link}><Button variant="contained">{Content}</Button></Link>
                                                    }


                                                },
                                            }
                                        case 'BUTTON':

                                            //console.log(d)
                                            return {
                                                field: Field, minWidth: minWidth, sortable: false, type: 'number', disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams<string>) => {
                                                    const Content = params.row[actualField];
                                                    const JobQuoteStatusID = params.row["JobQuoteStatusID"];
                                                    //    console.log("JobQuoteStatusID", JobQuoteStatusID)
                                                    if (d.LF === 'APPROVE') {


                                                        return <>{(JobQuoteStatusID === 1 || JobQuoteStatusID === -2) && <Approve object={params.row} jobId={Content} onClose={ReloadTable} />}</>
                                                    } else if (d.LF === 'DECLINE') {
                                                        return <>{(JobQuoteStatusID === 1 || JobQuoteStatusID === -2) && <Decline jobId={Content} onClose={ReloadTable} />}</>
                                                    } else {
                                                        return <></>
                                                    }
                                                },
                                            }
                                        case 'TEXT':
                                            searchCols.push(d.F);
                                            return {
                                                field: Field, minWidth: minWidth, disableColumnMenu: true, sortable: false, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams<string>) => {

                                                    const Content = params.row[actualField];


                                                    if (Content == null) {
                                                        return '';
                                                    }

                                                    return `${Content}`;


                                                },
                                            }
                                        case 'TEXTSORT':
                                                searchCols.push(d.F);
                                                return {
                                                    field: Field, minWidth: minWidth, disableColumnMenu: true, sortable: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams<string>) => {
    
                                                        const Content = params.row[actualField];
    
    
                                                        if (Content == null) {
                                                            return '';
                                                        }
    
                                                        return `${Content}`;
    
    
                                                    },
                                                }
                                        case 'TEXTFILTER':
                                            searchCols.push(d.F);
                                            return {
                                                field: Field, minWidth: minWidth, sortable: false, disableColumnMenu: false, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams<string>) => {

                                                    const Content = params.row[actualField];


                                                    if (Content == null) {
                                                        return '';
                                                    }

                                                    return `${Content}`;


                                                },
                                            }
                                        case 'FILE':

                                            return {
                                                field: d.F, minWidth: minWidth, sortable: false, disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams<string>) => {
                                                    const Content = params.row[actualField];
                                                    if (Content == null) {
                                                        return '';
                                                    }
                                                    return <Download variant="outlined" buttonText='' cacheId={Content} />
                                                },
                                            }
                                        case 'MEMO':
                                            searchCols.push(d.F);
                                            return {
                                                field: Field, minWidth: minWidth, disableColumnMenu: false, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams<string>) => {
                                                    const Content = params.row[actualField];

                                                    if (Content == null) {
                                                        return '';
                                                    }

                                                    return `${Content}`;


                                                },
                                            }
                                        case 'DATE':

                                            return {
                                                field: Field, minWidth: minWidth, type: 'date', disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams<Date>) => {
                                                    const Content = params.row[actualField];
                                                    if (Content == null) {
                                                        return '';
                                                    }
                                                    const d: Date = new Date(Date.parse(Content.toString()));


                                                    let date, month, year;

                                                    date = d.getDate();
                                                    month = d.getMonth() + 1; // take care of the month's number here ⚠️
                                                    year = d.getFullYear();
                                                    return <>{`${date}/${month}/${year}`}</>
                                                },
                                            }
                                        case 'YESNO':

                                            return {
                                                field: Field, minWidth: minWidth, type: 'boolean', disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams<boolean>) => {
                                                    const Content = params.row[actualField];
                                                    if (Content == null) {
                                                        return '';
                                                    }

                                                    if (Content) {
                                                        return <CheckIcon />
                                                    } else {
                                                        return <CloseIcon />
                                                    }

                                                },
                                            }
                                        case 'MONEY':


                                            return {
                                                field: Field, minWidth: minWidth, type: 'number', disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams<number>) => {
                                                    const Content = params.row[actualField];
                                                    if (Content == null) {
                                                        return '';
                                                    }


                                                    return <Box sx={{ width: "100%", textAlign: "left" }}>{`£ ${Content.toFixed(2)}`}</Box>;
                                                },
                                            }
                                        case 'PHONE':

                                            return {
                                                field: Field, minWidth: minWidth, disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams<string>) => {

                                                    const Content = params.row[actualField];

                                                    if (Content == null) {
                                                        return '';
                                                    }

                                                    // const valueFormatted = params.value;
                                                    return <Button variant='text' href={`tel:${Content}`}><PhoneIcon /></Button>;
                                                },
                                            }
                                        case 'EMAIL':


                                            return {
                                                field: Field, minWidth: minWidth, disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams<string>) => {
                                                    const Content = params.row[actualField];
                                                    if (Content == null) {
                                                        return '';
                                                    }

                                                    // const valueFormatted = params.value;
                                                    return <Button variant='text' href={`mailto:${Content}&subject=Email`}><EmailIcon /></Button>;
                                                }
                                            }
                                        case 'NUM':

                                            return {
                                                field: Field, minWidth: minWidth, type: 'number', disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams<number>) => {
                                                    const Content = params.row[actualField];

                                                    if (Content == null) {
                                                        return '';
                                                    }

                                                    const valueFormatted = Number(Content);
                                                    return `${valueFormatted}`;
                                                },
                                            }
                                        case 'OPENNOTE':


                                            return {
                                                field: Field, minWidth: minWidth, disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams<string>) => {
                                                    const Content = params.row[actualField];
                                                    if (Content == null) {
                                                        return '';
                                                    }
                                                    //       console.log("c", Content, actualField)

                                                    return <>{Content}</>
                                                }
                                            }
                                        default:
                                            console.log("WTF", d)
                                            return {
                                                field: Field, minWidth: minWidth, disableColumnMenu: true, headerName: d.FN, flex: flex, renderCell: (params: GridRenderCellParams<string>) => {
                                                    const Content = params.row[actualField];

                                                    return <>unknown {d.T} {Content}</>


                                                },
                                            }
                                    }
                                }));

                                xcolumns = xcolumns.map(x => {
                                    x.headerAlign = "left";
                                    x.align = 'left';
                                    return x;
                                })
                                setSearchCols(searchCols);
                                setColumns(xcolumns);
                                setTNS(response);
                                setOutputPayload(response.data)
                                setLoading(false)
                            } else {
                                setOutputPayload(response.data)

                            }

                        }
                    }
                }

            } catch (e) {
                setError(true);
                setLoading(false)
            }
        }
    }


    useEffect(() => {
        console.log("triggered this", url, triggerUrl)
        if (triggerUrl === url) {
            console.log("triggered this WIN", url, triggerUrl)
            ReloadTable();
            trigger && trigger('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerUrl])


    useEffect(() => {

        Go();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [search, setSearch] = useState('');


    useEffect(() => {
        if (tns) {
            const original: any[] = [...tns.data]
          //  console.log("Search", search, serchCols)
            if (search && search.length > 0) {
                const newPayload = original.filter((value, index) => {
                    let retOk = false;
                    for (let index = 0; index < serchCols.length; index++) {
                        const col = serchCols[index];
                        const vvalue = value[col]
                        if (vvalue && vvalue !== null) {
                            const colPayload = vvalue.toString().toLowerCase();

                            if (colPayload.indexOf(search.toLowerCase()) >= 0) {
                                retOk = true;
                            }
                        }

                    }
                    if (retOk === true) {
                        return value;
                    } else {
                        return undefined
                    }
                })

                setOutputPayload(newPayload);
            } else {
                setOutputPayload(original);
            }



        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    if (loading === true) {
        return <CircularProgress />
    }

    if (error === true) {
        return <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'

        }}>
            {triggerUrl}
            <Box sx={{ margin: "auto", paddingTop: 20 }}>
                <img src="/Airevalley-Transparent-small-1.png" alt="Logo" />

                <Typography variant="h5">Error</Typography>
                <Typography>Something went wrong there.<br />
                    {url}</Typography>

            </Box>

        </Box>
    }


    //https://mui.com/x/api/data-grid/data-grid/
    const alt = tns && tns.schema.filter(d => d.T === 'BURGER' || d.T === 'ROWLINK')

    const handleEvent: GridEventListener<'rowClick'> = (
        params: GridRowParams,
        event: MuiEvent<React.MouseEvent<HTMLElement>>,
        details: GridCallbackDetails
    ) => {

        const alt = tns && tns.schema.filter(d => d.T === 'OPENROW')[0]


        if (event.target instanceof Element) { /*...*/

            if (event.target.nodeName === 'DIV') {
                console.log("handleEvent", event.target.nodeName)
                let url = '';
                if (alt) {
                    const value = params.row[alt.F]
                    switch (alt.LF) {
                        case 'OPENQUOTE':
                            url = `/quote/${value}`;
                            break;
                        case 'OPENJOB':
                            url = `/jobs/${value}`;
                            break;
                        case 'OPENAPPLIANCE':
                            url = `/assets/${value}`;
                            break;
                        case 'OPENLOCATION':
                            url = `/locations/${value}`;
                            break;
                        case 'OPENQUOTE':
                            url = `/quote/${value}`;
                            break;
                    }


                    if (url !== '') {
                        navigate && navigate(url, { replace: false })
                    }



                } else {
                    const openNote = tns && tns.schema.filter(d => d.T === 'OPENNOTE')[0]
                    if (openNote) {
                        const value = params.row["Note"];
                        console.log(openNote, value)
                        handleOpen(value)
                    }

                }
            }

        }


    };

    let props: any = {
        disableColumnSelector: true,
        disableSelectionOnClick: true,
        rows: outputPayload.filter(d => {
            console.log("searchm", search)

            if (!search && (query || query1)) {
                let actQuery = query;
                if (actQuery === undefined) actQuery = {}
                if (query1 !== undefined) actQuery = { ...actQuery, ...query1 }


                let ret = false;
                const keys = Object.keys(actQuery);
                console.log("filter", actQuery, keys, d, d.StatusType)

                if (keys.length > 0) {
                    for (let index = 0; index < keys.length; index++) {
                        // try {
                        const key = keys[index];
                        // const data = d[key];
                        // const queryValue = actQuery[key];
                        if (d[key] === actQuery[key]) {
                            ret = true
                        }
                        // } catch (s) {

                        // }
                    }

                    if (ret === true) {
                        return d;
                    } else {
                        return undefined
                    }
                } else {
                    return d;
                }
            } else {
                return d
            }
        }),
        columns: columns,
        disableColumnFilter: true,
        components: {
            Toolbar: CustomToolbar,
            NoRowsOverlay: MissingRows,
            ColumnMenu: (props: any) => {
                const d = outputPayload.map(d => d[props.currentColumn.field]);
                let unique = [...new Set(d)];
                console.log(unique)

                let querysSet: string[] = [];
                let showReset = false;
                if (query1) {

                    querysSet = Object.getOwnPropertyNames(query1)
                    showReset = (querysSet.length > 0 ? true : false)
                }
                return <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

                    <List component="nav" aria-label="secondary mailbox folder">
                        {showReset === true && <ListItemButton
                            onClick={(ev) => {
                                ev.preventDefault();
                                const searchD: any = {}

                                setQuery1(searchD)
                            }}>
                            <ListItemText primary="Reset" />
                        </ListItemButton>}



                        {unique.map(d => {
                            let selected = false;
                            if (query1 && query1[props.currentColumn.field] && query1[props.currentColumn.field] === d) {
                                selected = true
                            }
                            return <ListItemButton
                                selected={selected}
                                key={d}
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    const searchD: any = {}
                                    searchD[props.currentColumn.field] = d
                                    setQuery1(searchD)

                                }}>    <ListItemText primary={d} />
                            </ListItemButton>
                        }
                        )}
                    </List>
                </Box >
            },
        },
        componentsProps: {
            toolbar: { combinedSearch, search, setSearch },
            //  columnMenu: { combinedSearch, search, setSearch }
        },
        pageSize: pageSize,
        onPageSizeChange: (newPageSize: number) => {
            console.log(newPageSize)
            setPageSize(newPageSize)
        },
        rowsPerPageOptions: [10, 25, 100],
        getRowClassName: (params: any) => {
            if (params.row["Appliance_Background"]) {
                return params.row["Appliance_Background"]
            } else {
                return "";
            }

        },
        onRowClick: handleEvent,
        initialState: {
            sorting: {
                sortModel: [{ field: 'id', sort: 'desc' }],
            },
        }

    }
    if (multi === true) {
        props.checkboxSelection = true
        props.onSelectionModelChange = (newSelectionModel: any) => {
            setSelectionModel(newSelectionModel);
        }
        props.selectionModel = selectionModel;
        props.componentsProps.toolbar.selectionModel = selectionModel;
        props.componentsProps.toolbar.doWithMulti = doWithMulti;
        if (tns) {
            props.componentsProps.toolbar.data = tns.data;
        }
    }
    console.log(url)



    return <Box>
        <SimpleDialog
            note={note}
            open={open}
            onClose={handleClose}
            onCancel={handleClose}
        />
        <ErrorBoundary>
            {title !== '' && <h2>{title}</h2>}

            {error === false ? <div style={{ height: height || '700px', width: "100%" }}>

                {columns !== undefined && <>
                    <DataGrid
                        {...props}

                    />

                </>}
            </div> : <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
            }}>

                <Box sx={{ margin: "auto", paddingTop: 20 }}>
                    <img src="/Airevalley-Transparent-small-1.png" alt="Logo" />

                    <Typography variant="h5">Error</Typography>
                    <Typography>Something went wrong there.</Typography>

                </Box>

            </Box>}

            {tns && <>

                <DebugButton data={tns} />
                <DebugButton buttonText='SCHEMA' data={tns.schema} />
                <DebugButton buttonText='SCHEMA' data={alt} />
            </>}



        </ErrorBoundary>
    </Box >
}

export default SqlTableMagic;