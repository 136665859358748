import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import doRequest from "./axios";
import { useNotify } from "./notify";
import { useTableContext } from "./table-provider";
import { buttonSX } from "./buttons";

// const style = {
//     position: 'absolute' as 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'white',
//     border: '2px solid #000',
//     boxShadow: 24,
//     pt: 2,
//     px: 4,
//     pb: 3,
// };
interface iApprove {
    jobId: any;
    width?:string;
    className?: string;
    onClose: () => void;
    variant?: 'outlined' | 'text';
}



export interface iSimpleDialogProps {
    open: boolean;
    onCancel: () => void;
    onClose: (note:string) => void;
}

const SimpleDialog = (props: iSimpleDialogProps) => {
    const { onClose, onCancel, open } = props;
    const [text, setText] = useState('');
    const handleClose = () => {
        onClose(text);
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };





    return (
        <Dialog onClose={handleClose} fullWidth open={open}>
            <DialogTitle>Decline</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure?
                </DialogContentText>

                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    value={text}
                    onChange={handleChange}
                    label="Note"
                    type="text"
                    multiline={true}
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button onClick={handleClose}>Decline</Button>
            </DialogActions>

        </Dialog>
    );
}

const Decline = ({ className = "", jobId, onClose, variant = 'outlined', width }: iApprove) => {
    // const [open, setOpen] = useState(false);
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => {
    //     onClose && onClose()
    //     setOpen(false)
    // };
    const btnSX:any = {};
    if(width){
        btnSX.width = width;
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
    //    onClose && onClose()
        setOpen(false)
    };

    const [loading, setLoading] = useState<boolean>(false);
    const location = useLocation();
    const { displayNote } = useNotify();
    const { trigger } = useTableContext()

    const declineThis = async (note:string) => {
        setLoading(true)
        try {
            const response = await doRequest<any>(`jobs/approveQuote/${jobId}`, 'POST', {
                approve: false, poNumber:note
            })
            console.log("static", response)
            if (response.X === 'OK') {
                console.log("static", response)
                displayNote && displayNote('You have declined a quote', 'success')

                if (location.pathname.includes('/quote')) {
                    trigger && trigger('quotes/list-declined');
                }


                onClose()
                setLoading(false)
            } else {
                displayNote && displayNote('Not valid', 'error')
                setLoading(false)
            }
        } catch {
            displayNote && displayNote('An Error occurred', 'error')
            setLoading(false)
        }
    }
    return <div>

        <SimpleDialog
            open={open}
            onClose={declineThis}
            onCancel={handleClose}
        />


        <Button sx={btnSX} className={className} variant={variant} onClick={async () => {
            handleOpen();
        }}>{loading ? "Loading" : 'Decline'}</Button>


    </div>
}



export default Decline;