

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { Snackbar, SnackbarContent, Typography } from "@mui/material";
import { createContext, useContext, useState } from "react";

export const NotifyDisplay = () => {
    const { display, hideNote, type, message } = useNotify();

    let color
    if (type === "success") {
        color = "#66BC0C";
    } else if (type === "error") {
        color = "#E02020";
    } else if (type === "warning") {
        color = "#fc7703";
    }



    const handleSnackbarClose = () => {
        hideNote && hideNote()
    }
    return <Snackbar
        anchorOrigin={{
            vertical: "top",
            horizontal: "right",


        }}
        open={display}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
    >
        <SnackbarContent
            aria-describedby="Notifications"
            style={{ backgroundColor: color, border: "1px solid #DFE3EB" }}
            message={
                <span
                    style={{
                        display: "flex",
                        paddingLeft: "8px",
                    }}
                >
                    {type === "success" && (
                        <CheckCircleIcon
                            style={{
                                marginTop: "26px",
                            }}
                        />
                    )}
                    {type === "error" && (
                        <ErrorIcon
                            style={{
                                marginTop: "26px",
                            }}
                        />
                    )}
                    {type === "warning" && (
                        <WarningIcon
                            style={{
                                marginTop: "26px",
                            }}
                        />
                    )}

                    <Typography
                        variant="body1"
                        style={{
                            color: "white",
                            marginLeft: "8px",
                            // backgroundColor: "white",
                            padding: "24px",
                        }}
                    >
                        {type === "success" && (
                            <div
                                style={{
                                    marginBottom: "8px",
                                }}
                            >
                                Confirmation
                            </div>
                        )}
                        {type === "error" && (
                            <div
                                style={{
                                    marginBottom: "8px",
                                }}
                            >
                                Something Failed
                            </div>
                        )}
                        {type === "warning" && (
                            <div
                                style={{
                                    marginBottom: "8px",
                                }}
                            >
                                Warning
                            </div>
                        )}
                        {message}
                    </Typography>
                </span>
            }
        />
    </Snackbar>
}

type NotifyContextType = {
    display: boolean,
    type: 'success' | 'error' | 'warning';
    message: string;
    displayNote?: (message: string, type: 'success' | 'error' | 'warning') => void;
    hideNote?: () => void
};
type Props = {
    children: React.ReactNode;
};
export const NotifyContext = createContext<NotifyContextType>({ display: false, message: '', type: 'error' });

const NotifyProvider = ({ children }: Props) => {
    const [display, setDisplay] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [type, setType] = useState<'success' | 'error' | 'warning'>('success')

    const displayNote = (message: string, type: 'success' | 'error' | 'warning') => {
        setMessage(message);
        setType(type);
        setDisplay(true);

    }

    const hideNote = () => {
        setDisplay(false)
    }

    const value: NotifyContextType = { display, message, type, displayNote, hideNote }
    return <NotifyContext.Provider value={value}>
        {children}
    </NotifyContext.Provider>
}
export const useNotify = () => useContext(NotifyContext)
export default NotifyProvider