import { Grid } from "@mui/material";
import GreyBox, { alwaysFullSpacing } from "../../util/greyBox";
import SqlTableMagic from "../../util/sqlTableMagic";
const Locations = () => {

    return <Grid container spacing={0}>
        <Grid item {...alwaysFullSpacing}>
            <GreyBox>
                <Grid container>

                    <Grid item md={2}>
                        <h2>Locations</h2>
                    </Grid>

                </Grid>
                <SqlTableMagic title="" url="locations/list" requestType='GET' height="700px" />
            </GreyBox>
        </Grid>

    </Grid>
}


export default Locations