import { Route, Routes } from "react-router-dom";
import SecureRoute from "../../util/secureRoute";
import Job from "./job";
import Jobs from "./jobs";


const JobsRoutes = () => {

    return <Routes>
        <Route path="/" element={<SecureRoute title="Jobs"><Jobs /></SecureRoute>} />
        <Route path="/:id" element={<SecureRoute title="Job"><Job /></SecureRoute>} />
    </Routes>
}


export default JobsRoutes;