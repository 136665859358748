

import React, { createContext, useContext, useState } from "react";

type TableContextType = {
    triggerUrl: string;
    trigger?: (url: string) => void
};
type Props = {
    children: React.ReactNode;
};
export const TableContext = createContext<TableContextType>({ triggerUrl: '' });

const TableProvider = ({ children }: Props) => {
    const [triggerUrl, setUrl] = useState('')
    const trigger = (urlA: string) => {
        console.log('trigger')
        setUrl(urlA)
    }

    const value: TableContextType = { triggerUrl, trigger }
    return <TableContext.Provider value={value}>
        <>{triggerUrl}{children}
        </>

    </TableContext.Provider>
}
export const useTableContext = () => useContext(TableContext)
export default TableProvider