import { Button, Grid, TableHead, Typography } from "@mui/material";
import GreyBox, { alwaysFullSpacing } from "../../util/greyBox";

import { useEffect, useMemo, useRef, useState } from "react";
import doRequest from "../../util/axios";
import DebugButton from "../../util/debugButton";
import { SxProps, useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useNavigate, useParams } from "react-router-dom";
import { Order, ScheduleHeader, getComparator, stableSort } from "./utils";
import Download from "../../util/download";

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

interface iDisplayDateProp {
    date: string;
}
const DisplayDate = ({ date }: iDisplayDateProp) => {

    const d: Date = new Date(Date.parse(date));

    if (!isNaN(Number(d))) {
        let date, month, year;

        date = d.getDate();
        month = d.getMonth() + 1; // take care of the month's number here ⚠️
        year = d.getFullYear();

        return <>{`${date}/${month}/${year}`}</>

    }
    return <></>
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}



const SiteServiceSchedule = () => {
    const mounted = useRef(false)
    const [data, setData] = useState<any[]>([]);
    const [page, setPage] = useState(0);
    const [serviceId, setServiceId] = useState(0);
    const [certificateId, setCertificateId] = useState(0);
    const [name, setName] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const navigate = useNavigate();
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState('Site');


    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const { id } = useParams();

    useEffect(() => {

        const Go = async () => {
            const response = await doRequest<any>(`reports/sReportServiceSchedule/${id}`, 'GET', {})
            console.log(response)
            setData(response);

            if (response.length >= 1) {
                setName(response[0].ApplianceSite)
                setServiceId(response[0].SiteServiceCacheID)
                setCertificateId(response[0].SiteGasCertID)
            }
        }
        if (mounted.current === false) {
            mounted.current = true;

            Go();
        }
    }, [id]);

    const headXS: SxProps = {
        fontWeight: "bold", verticalAlign: "bottom"
    }
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = useMemo(
        () =>
            stableSort(data, getComparator(order, orderBy)),
        [order, orderBy, data],
    );

    return <Grid container spacing={0}>
        <Grid item {...alwaysFullSpacing}>
            <GreyBox>
                <Grid container>
                    <Grid item md={8}>
                        <h2>Service Schedule {name}
                        </h2>
                    </Grid>
                    <Grid item md={4} sx={{textAlign:'right', mt:2
                }}>
                        {serviceId !== 0 && <Download className="" buttonText="Last Service Report" cacheId={serviceId} />}
                        {certificateId !== 0 && <Download className="" buttonText="Gas Certificate" cacheId={certificateId} />}
                    </Grid>
                    <Grid item md={12}>

                        <TableContainer sx={{ maxHeight: "70vh" }}>
                            <Table sx={{ minWidth: 500 }} aria-label="Site" stickyHeader>
                                <TableHead>

                                    <TableRow>
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="ApplianceAssetNumber" title="Asset Number" onRequestSort={handleRequestSort} />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="ApplianceType" title="Appliance" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="ApplianceManufacturer" title="Make" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="ApplianceModel" title="Model" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="ApplianceSerialNumber" title="Serial Number" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="ApplianceGER" title="Type" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="ApplianceChecklistConditionRating" title="Condition" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="AppliancePPM" title="Cost for Service" onRequestSort={handleRequestSort} align="center" />
                                        <ScheduleHeader order={order} orderBy={orderBy} keyRef="TwelveMonthRepairCost" title="12 Month Repair Cost" onRequestSort={handleRequestSort} align="center" />

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(visibleRows
                                    ).map((row) => {
                                        if (typeof row.Appliance_Background === "string") {
                                            return <TableRow key={row.name} className={row.Appliance_Background} onClick={(ev) => {
                                                ev.preventDefault();
                                                navigate(`/assets/${row.ApplianceID}`)
                                            }}>
                                                <TableCell component="th" style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }}>
                                                    {row.ApplianceAssetNumber}
                                                    <DebugButton data={data} />
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center">
                                                    {row.ApplianceType}
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center">
                                                    {row.ApplianceManufacturer}
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center">
                                                    {row.ApplianceModel}
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center">
                                                    {row.ApplianceSerialNumber}

                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center">
                                                    {row.ApplianceGER}

                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center">
                                                    {row.ApplianceChecklistConditionRating}

                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center">
                                                    {typeof row.AppliancePPM === 'number' && "£ " + row.AppliancePPM.toFixed(2)}
                                                </TableCell>
                                                <TableCell style={{ width: 160, borderLeft: "1px solid rgba(224, 224, 224, 1)" }} align="center">
                                                    {typeof row.TwelveMonthRepairCost === 'number' && "£ " + row.TwelveMonthRepairCost.toFixed(2)}

                                                </TableCell>
                                            </TableRow>
                                        }
                                    })}

                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Grid>

                </Grid>
            </GreyBox>
        </Grid>

    </Grid>
}


export default SiteServiceSchedule