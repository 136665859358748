import { Route, Routes } from "react-router-dom";
import AssetsRoutes from "./assets";
import AuthRoutes from "./auth";
import DashRoutes from "./dashboard";
import JobsRoutes from "./jobs";
import LocationsRoutes from "./location";
import QuotesRoutes from "./quotes";
import RepairRoutes from "./repair";
import ServiceRoutes from "./service";

import SpendRoutes from "./spend";



const IndexRoutes = () => {
    return <Routes>
        <Route path="/*" element={<DashRoutes />} />
        <Route path="login/*" element={<AuthRoutes />} />
        <Route path="repair/*" element={<RepairRoutes />} />
        <Route path="jobs/*" element={<JobsRoutes />} />
        <Route path="locations/*" element={<LocationsRoutes />} />
        <Route path="assets/*" element={<AssetsRoutes />} />
        <Route path="quote/*" element={<QuotesRoutes />} />
        <Route path="serviceSchedule/*" element={<ServiceRoutes />} />
        <Route path="spend/*" element={<SpendRoutes/>} />
    </Routes>
}


export default IndexRoutes