import { Box, Button, Grid } from "@mui/material";
import { useState } from "react";
import GreyBox, { alwaysFullSpacing } from "../../util/greyBox";
import SqlTableMagic from "../../util/sqlTableMagic";
const Quotes = () => {


    const [query, setQuery] = useState<any | undefined>({ JobQuoteStatus: "Live" });



    return <Grid container spacing={0}>
        <Grid item {...alwaysFullSpacing}><GreyBox>

            <Grid container>

                <Grid item md={2}>
                    <h2>Quotes</h2>
                </Grid>
                <Grid item md={10}>
                    <Box sx={{ textAlign: 'right', paddingTop: 2 }}>
                        <Button onClick={() => {
                            setQuery(undefined)
                        }} className={`${query === undefined && 'activeButton'}`}>
                            All
                        </Button>

                        <Button onClick={() => {
                            setQuery({ JobQuoteStatus: "Live" })
                        }} className={`${query !== undefined && query.JobQuoteStatus === "Live" && 'activeButton'}`}>
                            Live
                        </Button>

                        <Button onClick={() => {
                            setQuery({ JobQuoteStatus: "Accepted" })
                        }} className={`${query !== undefined && query.JobQuoteStatus === "Accepted" && 'activeButton'}`}>
                            Approved
                        </Button>

                        <Button onClick={() => {
                            setQuery({ JobQuoteStatus: "Declined" })
                        }} className={`${query !== undefined && query.JobQuoteStatus === "Declined" && 'activeButton'}`}>
                            Declined
                        </Button>

                        <Button onClick={() => {
                            setQuery({ JobQuoteStatus: "Expired" })
                        }} className={`${query !== undefined && query.JobQuoteStatus === "Expired" && 'activeButton'}`}>
                            Expired
                        </Button>

                        <Button onClick={() => {
                            setQuery({ JobQuoteStatus: "Requote" })
                        }} className={`${query !== undefined && query.JobQuoteStatus === "Requote" && 'activeButton'}`}>
                            Requote
                        </Button>

                    </Box>
                </Grid>
            </Grid>


            <SqlTableMagic title="" url="quotes/list-live" requestType='GET' height="700px" query={query} />  </GreyBox></Grid >

    </Grid >
}


export default Quotes