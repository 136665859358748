import { CircularProgress, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import doRequest from "../../util/axios";
import DisplaySomething from "../../util/displaySomething";
import Download from "../../util/download";
import GreyBox, { alwaysFullSpacing, containerSpacing } from "../../util/greyBox";
import NotFound from "../../util/not-found";
import SqlTableMagic from "../../util/sqlTableMagic";
import TabsThing from "../../util/tabs";
import DebugButton from "../../util/debugButton";
const Location = () => {
    const params = useParams();

    const [location, setLocation] = useState<any>();
    const [tabsInfo, setTabsInfo] = useState<any[]>();
    const [title, setTitle] = useState<string>();
    const mounted = useRef(false);
    useEffect(() => {
        const Go = async () => {
            const response = await doRequest<any>(`locations/location/${params.id}`, 'GET', {})
            console.log("static", response)
            if (response[0] && response[0].length > 0) {
                setLocation(response[1][0])
                setTabsInfo(response[0])
                setTitle(response[1][0].CompanyLocation1Address)
            } else {
                setLocation(null)
            }
        }
        if (mounted.current === false) {
            mounted.current = true;
            Go();
        }

    }, [params.id])


    if (location === undefined) {
        return <CircularProgress />
    }
    if (location === null) {
        return <NotFound />
    }

    const headSX = {
        paddingLeft: 1
    }

    return <Grid container spacing={0}>
        <Grid item  {...alwaysFullSpacing}>
            <Typography sx={headSX} variant="h3">{title}</Typography>

            <Grid container spacing={0}>
                <Grid item container  {...alwaysFullSpacing} spacing={0}>
                    <Grid item {...containerSpacing}>
                        <GreyBox>
                            <Typography sx={headSX} variant="h4">Site Details</Typography>
                            <DisplaySomething payload={location} field="CompanyLocation1Address" text="Site Name" size={12} />
                            <DisplaySomething payload={location} field="CompanyLocationAddress" text="Address" size={12} />
                            <DisplaySomething payload={location} field="CompanyLocationPostcode" text="Postcode" size={12} />
                            {location.CompanyLocationTelephone && <DisplaySomething payload={location} field="CompanyLocationTelephone" text="Telephone" type="phone" size={12} />}
                        </GreyBox>

                    </Grid>

                    <Grid item {...containerSpacing}>
                        {location.CompanyHideGasCertificateYN === false && <GreyBox>
                            <Typography sx={headSX} variant="h4">Gas Certificate</Typography>
                            {location.LastGas_LocationDate && <DisplaySomething payload={location} field="LastGas_LocationDate" text="Gas Certificate Date" type="date" size={12} />}
                            {location.NextGas_LocationDate && <DisplaySomething payload={location} field="NextGas_LocationDate" text="Gas Certificate Due" type="date" size={12} />}
                            {location.LastGasLocation_Warning && <DisplaySomething payload={location} field="LastGasLocation_Warning" text="Gas Certificate Warning" size={12} />}
                            {location.LastGas_GasCertLocationCacheID !== 0 && <Box sx={{ textAlign: 'center', paddingBottom: 2 }}>

                                <Download buttonText="Download Certificate" width="20vw" cacheId={location.LastGas_GasCertLocationCacheID} />
                            </Box>}

                        </GreyBox>}


                        {(location.CompanyLocationNoGasServiceYN === false || location.CompanyLocationNoRefrigerationServiceYN === false || location.CompanyLocationNoElectricalServiceYN === false) && <GreyBox>

                            <table style={{ width: "100%" }}>
                                <tbody>

                                    <tr>
                                        <th></th>
                                        <th style={{ textAlign: "right" }}>Last Service</th>
                                        <th style={{ textAlign: "right" }}>Service Due</th>
                                        <th style={{ textAlign: "right" }}>Estimated Costs</th>
                                    </tr>
                                    {location.CompanyLocationNoGasServiceYN === false && <tr>
                                        <td>Gas</td>
                                        <td>{location.ServiceGasDate && <DisplaySomething payload={location} field="ServiceGasDate" text="" type="date" size={12} />}</td>
                                        <td>{location.ServiceGasNextDate && <DisplaySomething payload={location} field="ServiceGasNextDate" text="" type="date" size={12} />}</td>
                                        <td style={{ textAlign: "right" }}>{location.GasCost !== null && <>£ {location.GasCost.toFixed(2)}</>}</td>
                                    </tr>}
                                    
                                    {location.CompanyLocationNoRefrigerationServiceYN === false &&<tr>
                                        <td>Refrigeration</td>
                                        <td>{location.ServiceRefrigerationDate && <DisplaySomething payload={location} field="ServiceRefrigerationDate" text="" type="date" size={12} />}</td>
                                        <td>{location.ServiceRefrigerationNextDate && <DisplaySomething payload={location} field="ServiceRefrigerationNextDate" text="" type="date" size={12} />}</td>
                                        <td style={{ textAlign: "right" }}>{location.RefrigerationCost !== null && <>£ {location.RefrigerationCost.toFixed(2)}</>}</td>
                                    </tr>}
                                    {location.CompanyLocationNoElectricalServiceYN === false && <tr>
                                        <td>Electrical</td>
                                        <td>{location.ServiceElectricalDate && <DisplaySomething payload={location} field="ServiceElectricalDate" text="" type="date" size={12} />}</td>
                                        <td>{location.ServiceElectricalNextDate && <DisplaySomething payload={location} field="ServiceElectricalNextDate" text="" type="date" size={12} />}</td>
                                        <td style={{ textAlign: "right" }}>{location.ElectricalCost && <>£ {location.ElectricalCost.toFixed(2)}</>}</td>
                                    </tr>}

                                    <tr>

                                        <td><b>Site Total:</b></td>
                                        <td></td>
                                        <td></td>
                                        <td style={{ textAlign: "right" }}>{location.CompanyLocationServiceCost !== null && <>
                                            <b>£ {location.CompanyLocationServiceCost.toFixed(2)}</b></>}

                                        </td>




                                    </tr>
                                </tbody>
                            </table>



                        </GreyBox>}
                    </Grid>
                </Grid>
                {/* <Grid item container md={12} spacing={0}>

               
                <DisplaySomething payload={location} field="CompanyLocationAddress" text="Address" />
                <DisplaySomething payload={location} field="CompanyLocationPostcode" text="Postcode" />
                <DisplaySomething payload={location} field="CompanyLocationTelephone" text="Telephone" type="phone" />
               
                {/* <Divider style={{ width: '100%' }} /> 
            </Grid> */}
                <DebugButton data={location} />
              
            </Grid>
            {tabsInfo !== undefined && <Grid item {...alwaysFullSpacing}>  <GreyBox>
                <TabsThing tabs={tabsInfo.map((ti, tii) => {
                    return { title: ti.FN, content: <SqlTableMagic combinedSearch={false} title={''} url={`locations/${ti.LF}/${params.id}`} requestType='GET' /> }
                })} />

            </GreyBox></Grid>}

        </Grid>
    </Grid>
}


export default Location