import { Route, Routes } from "react-router-dom";
import SecureRoute from "../../util/secureRoute";
import Spend from "./spend";
import SiteSpend from "./site-spend";


const SpendRoutes = () => {

    return <Routes>
        <Route path="/" element={<SecureRoute title="Spend"><Spend /></SecureRoute>} />
        <Route path="/:id" element={<SecureRoute title="Site Spend"><SiteSpend /></SecureRoute>} />
    </Routes>
}


export default SpendRoutes;