import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

interface iDump {
    data: any;

}
const DataDump = ({ data }: iDump) => {
    const [visible, setVisible] = useState(false);
    const ent = Object.entries(data)


    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {

        return <Box>{visible && <Grid container>
            {ent.map((v, index) => {
                const key = v[0]
                const dataA = data[key]
                return <Grid md={6} item key={index}>{key} = {dataA}</Grid>
            })}

        </Grid>}
            <Button fullWidth onClick={() => setVisible(!visible)}>toggle</Button></Box>
    } else {
        return <></>
    }
}



export default DataDump;