import { Route, Routes } from "react-router-dom";
import SecureRoute from "../../util/secureRoute";
import Repair from "./repair";


const RepairRoutes = () => {

    return <Routes>
        <Route path="/" element={<SecureRoute title="Repairs">d</SecureRoute>} />
        <Route path="/:id" element={
            <SecureRoute title="Repair">
                <Repair />
            </SecureRoute>} />
    </Routes>
}


export default RepairRoutes;