import { Box, Typography } from "@mui/material";
import { Component, ErrorInfo, ReactNode } from "react";

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
    info?: Error;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false

    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, info: _ };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'

            }}>

                <Box sx={{ margin: "auto", paddingTop: 20 }}>
                    <img alt="logo" src="/Airevalley-Transparent-small-1.png" />

                    <Typography variant="h5">Error</Typography>
                    <Typography>Something went wrong there.</Typography>

                </Box>

            </Box>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
