import { Box, SxProps, TableCell, TableSortLabel } from "@mui/material";
import { visuallyHidden } from '@mui/utils';
export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export type Order = 'asc' | 'desc';
export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}




// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



const headXS: SxProps = {
    fontWeight: "bold", verticalAlign: "bottom",borderLeft: "1px solid rgba(224, 224, 224, 1)"
}

interface iScheduleHeader {
    align?: 'left' | 'center' | 'right'
    order: Order;
    className?: string;
    orderBy: string;
    keyRef: string;
    title: string;
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: string,
    ) => void;
}
export const ScheduleHeader = ({ order, orderBy, keyRef, title, onRequestSort, align = 'left', className = '' }: iScheduleHeader) => {
    const createSortHandler =
        (property: string) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return <TableCell
        align={align}
        key={keyRef}
        sx={headXS}
        sortDirection={orderBy === keyRef ? order : false}
        className={className}
    >
        <TableSortLabel
            active={orderBy === keyRef}
            direction={orderBy === keyRef ? order : 'asc'}
            onClick={createSortHandler(keyRef)}
        >
            {title}
            {orderBy === keyRef ? (
                <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
            ) : null}
        </TableSortLabel>
    </TableCell>
}
