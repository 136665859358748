import { Button, Grid } from "@mui/material"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import doRequest from "../../util/axios"
import { useAuth } from "../../util/context"
import TextInputField from "../../util/fields/text-field"
import FormContainer from "../../util/form-container"
import { useNotify } from "../../util/notify"
const Profile = () => {

    const { displayNote } = useNotify();
    const auth = useAuth();
    const navigate = useNavigate();

    const [emailLoading, setEmailLoading] = useState<boolean>(false);
    const [closedEmailLoading, setClosedEmailLoading] = useState<boolean>(false);
    const emailOptIn = async (PortalUserDontEmailYN: boolean) => {
        setEmailLoading(true);
        const response = await doRequest<any>('auth/emailOptIn', "POST", { PortalUserDontEmailYN });
        console.log("emailOptIn", response)

        auth && auth.getSession && auth.getSession();
        setEmailLoading(false)
    }    

    const sPortalUserClosedEmail = async (PortalUserClosedEmailYN: boolean) => {
        setClosedEmailLoading(true);
        const response = await doRequest<any>('auth/sPortalUserClosedEmail', "POST", { PortalUserClosedEmailYN });
        console.log("emailOptIn", response)

        auth && auth.getSession && auth.getSession();
        setClosedEmailLoading(false)
    }



    return <Grid container alignItems="center">
        <Grid item lg={3} md={3} sm={3} />
        <Grid md={6} item>
            <h2>Password</h2>
            <FormContainer onSubmit={async ({ oldPassword, newPassword }: any) => {
                const response = await doRequest<any>('auth/change-password', "POST", { oldPassword, newPassword })
                console.log("change-password", response)

                if (response && response.X === 'OK') {
                    displayNote && displayNote("Password has been updated", 'success');
                    navigate && navigate('../', { replace: false })
                } else {
                    displayNote && displayNote("Something went wrong there please check and try again", 'error')
                }
            }}
                requiredFields={['oldPassword', 'newPassword', 'newPassword2']}
                buttonText="Update Password">
                <TextInputField name="oldPassword" label="Current Password" type="password" required={true} />
                <TextInputField name="newPassword" label="New Password" type="password" required={true} />
                <TextInputField name="newPassword2" label="New Password Again" type="password" required={true} />

            </FormContainer>
        </Grid>
        <Grid item lg={3} md={3} sm={3} />
        {auth && auth.PortalUserLimit !== 0 && <>
            <Grid item lg={3} md={3} sm={3} />
            <Grid md={6} item>

                <h2>User Quote Approval Limit</h2>
                <p>£ {auth && auth.PortalUserLimit}</p>



            </Grid>
            <Grid item lg={3} md={3} sm={3} />
        </>}
        <Grid item lg={3} md={3} sm={3} />
        <Grid md={6} item>
            <h2>Weekly live quotes email opt in/out. Sent Wednesdays 10 am</h2>

            <p>


                {auth && auth.PortalUserDontEmailYN === true ? <><Button disabled={emailLoading} type="button" onClick={() => {
                    emailOptIn(false)
                }}>{emailLoading ? "Loading" : 'Disable Quote Email'}</Button></> : <><Button disabled={emailLoading} type="button" onClick={() => {
                    emailOptIn(true)
                }}>{emailLoading ? "Loading" : 'Enable Quote Email'}</Button></>}

            </p> 
            
            <h2>Automatically receive an email when jobs are complete.</h2>

            <p>
                {auth && auth.PortalUserClosedEmailYN === false &&<Button disabled={closedEmailLoading} type="button" onClick={() => {
                    sPortalUserClosedEmail(true)
                }}>{closedEmailLoading ? "Loading" : 'Enable Job Email'}</Button>}


                {auth && auth.PortalUserClosedEmailYN === true && <Button disabled={closedEmailLoading} type="button" onClick={() => {
                    sPortalUserClosedEmail(false)
                }}>{closedEmailLoading ? "Loading" : 'Disable Job Email'}</Button>}

            </p>
        </Grid>
    </Grid >
}

export default Profile