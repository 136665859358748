import { Route, Routes } from "react-router-dom";
import InsecureRoute from "../../util/insecureRoute";
import ForgotPassword from "./forgotPassword";
import Login from "./login";
import ResetPassword from "./resetPassword";


const AuthRoutes = () => {
    return <Routes>

        <Route path="/forgot-password" element={<InsecureRoute title="Forgot Password"><ForgotPassword /></InsecureRoute>} />
        <Route path="/reset-password/:code" element={<InsecureRoute title="Reset Password"><ResetPassword /></InsecureRoute>} />
        <Route path="/" element={<InsecureRoute title="Login"><Login /></InsecureRoute>} />
    </Routes>
}


export default AuthRoutes