
import { Button, Grid } from "@mui/material";
import arrayMutators from 'final-form-arrays';
import { useState } from "react";
import { Form } from "react-final-form";


interface iForm {
    buttonText: string;
    onSubmit: (data: any) => Promise<void>;
    
    data?: any;
    requiredFields?: string[];
    additionalButtons?: React.ReactNode;
    icon?: React.ReactNode;
    children: React.ReactNode;
}


const FormContainer = ({ requiredFields, buttonText, onSubmit, data, children, additionalButtons, icon }: iForm) => {
    const [loading, setLoading] = useState<boolean>(false)
    return <Form
        initialValues={data}
        mutators={{
            // potentially other mutators could be merged here
            ...arrayMutators
        }}
        subscription={{ values: true, submitting: true, pristine: true }}
        onSubmit={async (d: any) => {
            setLoading(true)
            await onSubmit(d)
            setLoading(false)
        }}

        validate={(values) => {
            const errors: any = {};

            if(requiredFields){
                requiredFields.forEach((requiredField) => {
                    if (!values[requiredField]) {
                        errors[requiredField] = "Required";
                    }
                });
            }
            

            // if (
            //     values.email &&
            //     !validator.isEmail(values.email) &&
            //     !values.email.includes(".con") &&
            //     !values.email.includes(".coma")
            // ) {
            //     errors.email = "Invalid Email";
            // }

            return errors;
        }}
        render={({
            handleSubmit,
            pristine,
            values,
            submitting,
            invalid,
            dirtySinceLastSubmit,
        }) => (
            <form
                autoComplete="off"
                onSubmit={handleSubmit}
                noValidate
            >

                <Grid
                    container
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {children}

                    <Grid item xs={12} sm={12} md={12}>

                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={
                                (invalid && !dirtySinceLastSubmit) || loading
                            }
                            type="submit"
                        >
                            {buttonText}
                            <span style={{ marginLeft: "2px" }}>{icon && icon}</span>
                        </Button>
                        {/* <DebugButton data={values} /> */}
                        {additionalButtons && additionalButtons}
                    </Grid>


                </Grid>
            </form>
        )}
    />
}


export default FormContainer
