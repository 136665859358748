import { createContext, useContext, useEffect, useRef, useState } from "react";
import { iSessionRequest } from "../interfaces/session";
import doRequest from "./axios";

type AuthContextType = {
    loggedIn: boolean;
    name?: string;
    id?: string;
    company?: string;
    email?: string;
    __VERSION?: string;
    userId?: number;
    CompanyPONumberRequiredYN?: boolean;
    PortalUserDontEmailYN?: boolean;
    PortalUserLimit?: number;
    PortalUserClosedEmailYN?:boolean;
    


    CompanyHideGasCertificateYN?: boolean;
    CompanyHideGasServiceYN?: boolean;
    CompanyHideRefrigerationServiceYN?: boolean;
    CompanyHideElectricalServiceYN?: boolean;
    CompanyHideCoffeeYN?: boolean;


    logIn?: () => Promise<any>;
    logOut?: () => void;
    getSession?: () => void;
    setPortalUserDontEmailYN?: (bool: boolean) => void;
    // hasPermission?: (roles: string[]) => boolean;
};
const sampleAppContext: AuthContextType = {
    loggedIn: false,
};
export const AuthContext = createContext<AuthContextType>(sampleAppContext);



type Props = {
    children: React.ReactNode;
};

const AuthProvider = ({ children }: Props) => {
    const [loaded, setLoaded] = useState<boolean>(false)
    const [loggedIn, setLoggedIn] = useState<boolean>(false)
    const [name, setName] = useState<string | undefined>(undefined)
    const [company, setCompany] = useState<string | undefined>(undefined)
    const [id, setId] = useState<string | undefined>(undefined)
    const [email, setEmail] = useState<string | undefined>(undefined)
    const [userId, setUserId] = useState<number | undefined>(undefined);
    const [PortalUserLimit, setPortalUserLimit] = useState<number | undefined>(undefined);
    const [CompanyPONumberRequiredYN, setCompanyPONumberRequiredYN] = useState<boolean | undefined>(undefined);
    const [PortalUserDontEmailYN, setPortalUserDontEmailYN] = useState<boolean | undefined>(undefined);
    const [PortalUserClosedEmailYN, setPortalUserClosedEmailYN] = useState<boolean | undefined>(undefined);

    const [CompanyHideGasCertificateYN, setCompanyHideGasCertificateYN] = useState<boolean | undefined>(undefined);

    const [CompanyHideGasServiceYN, setCompanyHideGasServiceYN] = useState<boolean | undefined>(undefined);

    const [CompanyHideRefrigerationServiceYN, setCompanyHideRefrigerationServiceYN] = useState<boolean | undefined>(undefined);

    const [CompanyHideElectricalServiceYN, setCompanyHideElectricalServiceYN] = useState<boolean | undefined>(undefined);

    const [CompanyHideCoffeeYN, setCompanyHideCoffeeYN] = useState<boolean | undefined>(undefined);








    const [__VERSION, setVersion] = useState<string | undefined>(undefined)
    const getSession = async () => {
        try {
            const response = await doRequest<iSessionRequest>('auth', "GET", {})
            console.log("getSession", response)
            if (response) {
                setLoggedIn(response.loggedIn);
                setId(response.sid)
                setCompany(response.Company);
                setName(response.PortalUserName)
                setLoaded(true)
                setEmail(response.PortalUserEmail);
                setUserId(response.PortalUserID)
                setVersion(response.__VERSION)
                setCompanyPONumberRequiredYN(response.CompanyPONumberRequiredYN)
                setPortalUserDontEmailYN(response.PortalUserDontEmailYN);
                setPortalUserLimit(response.PortalUserLimit);
                setPortalUserClosedEmailYN(response.PortalUserClosedEmailYN)





                setCompanyHideGasCertificateYN(response.CompanyHideGasCertificateYN);
                setCompanyHideGasServiceYN(response.CompanyHideGasServiceYN)
                setCompanyHideRefrigerationServiceYN(response.CompanyHideRefrigerationServiceYN)
                setCompanyHideElectricalServiceYN(response.CompanyHideElectricalServiceYN)
                setCompanyHideCoffeeYN(response.CompanyHideCoffeeYN)


                return true;
            } else {
                setLoaded(true)
            }
        } catch {
            console.log("getSession Catch")
        }
    }

    const logoutCall = async () => {
        try {

            const response = await doRequest<iSessionRequest>('auth/logout', "POST", {})
            console.log("logoutCall", response)
            if (response) {

                setLoggedIn(false);
                setId(undefined)
                setName(undefined)
            }
            return true;
        } catch {
            console.log("response Catch")
        }
    }

    const mounted = useRef(false);
    useEffect(() => {
        if(mounted.current===false){
            mounted.current = true;
            getSession()
        }
    
    }, [])

    const logIn = async () => {
        //  console.log("logIn", value)
        return getSession();
    }

    const logOut = async () => {
        //    console.log("logOut", value)
        return logoutCall();
    }
    const value: AuthContextType = { loggedIn, name, email, userId, id, __VERSION, company, CompanyPONumberRequiredYN, PortalUserDontEmailYN,PortalUserClosedEmailYN, PortalUserLimit, CompanyHideGasCertificateYN, CompanyHideGasServiceYN, CompanyHideRefrigerationServiceYN, CompanyHideElectricalServiceYN, CompanyHideCoffeeYN, logOut, logIn, getSession, setPortalUserDontEmailYN }
    return (
        <AuthContext.Provider value={value}>
            {loaded === true && <>{children}</>}

        </AuthContext.Provider>
    );
}
export const useAuth = () => useContext(AuthContext)
export default AuthProvider