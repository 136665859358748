import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState } from 'react';





interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface iTab {
    title: string;
    content: React.ReactNode
}


interface iTabs {
    tabs: iTab[]
}

const TabsThing = ({ tabs }: iTabs) => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return <>
        <Box sx={{ borderBottom: 1, borderColor: 'red', }} >
            <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { background: 'red' } }} aria-label="basic tabs example">
                {tabs.map((t, ti) => {
                    return <Tab key={ti} label={t.title} {...a11yProps(ti)} />
                })}
            </Tabs>
        </Box>
        {
            tabs.map((t, ti) => {
                return <TabPanel key={ti + '_'} value={value} index={ti}>
                    {t.content}
                </TabPanel>
            })
        }

    </>
}


export default TabsThing;